import Vue from 'vue'
import DatePicker from './picker'
import router from '@/router'

const DatePickerConstructor = Vue.extend(DatePicker)

let ins = null

const selectDate = (
  {
    type = 'date',
    date = new Date(),
    minDate,
    maxDate,
    filter
  } = {}
) => {
  if (!ins) {
    ins = new DatePickerConstructor({
      el: window.document.createElement('div')
    })
    window.document.body.appendChild(ins.$el)
  }
  router.afterEach(to => {
    if (ins){
      ins.reject()
      ins.visible=false
    }
  })
  return new Promise((resolve, reject) => {
    ins.type = type
    ins.init({ date, minDate, maxDate, filter })
    ins.resolve = resolve
    ins.reject = reject
  })
}

export default selectDate
