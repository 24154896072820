<template>
  <form-select-wrap :showPicker="showPicker"
                    :cell-list="cellList"
                    :multiple="true"
                    :max="1"
                    :min="1"
                    :loading="loading"
                    :finished="finished"
                    :showTab="options.showTab"
                    @cancel="cancel"
                    @confirm="confirm"
                    @onload="getSalesList">
    <form slot="search" action="#">
      <van-search
        v-model="keyword"
        clearable
        placeholder="姓名/电话"
        @search="onSearch"
      />
    </form>
  </form-select-wrap>

</template>

<script>
import store from '@/store'
import formSelectWrap from './form-select-wrap'
import baseDataServices from '@/services/baseDataServices'
export default {
  resolve: null,
  reject: null,
  components: {
    formSelectWrap
  },
  data() {
    return {
      cellList: [],
      showPicker: false,
      loading: false,
      finished: false,
      pageSize: 999,
      pageIndex: 1,
      totalCount: 0,
      options: {},
      keyword:'',
      roleLogo: '1014002',
      withPermission: false
    }
  },
  computed: {
    userInfo() {
      const { userInfo } = store.getters
      return userInfo
    },
  },
  mounted() {
    
  },
  methods: {
    onSearch(){
      this.pageIndex = 1
      this.cellList = []
      this.loading = true
      this.getSalesList()
    },
    // 获取销售顾问列表
    async getSalesList(value) {
      try {
        this.loading = true
        const params = {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          ...this.options,
        }
        if (this.keyword.trim())params.keyword = this.keyword.trim()
        let resData = []
        resData = await baseDataServices.getStaffList({ name: this.keyword, roleLogo: this.roleLogo, dealerId: this.userInfo.dealers[0]?.id, withPermission: this.withPermission })
        this.loading = false
        this.totalCount = resData.length
        if (this.pageSize * this.pageIndex < this.totalCount) {
          this.pageIndex = this.pageIndex + 1
          this.finished = false
        } else {
          this.finished = true
        }
        if (resData && resData.length > 0) {
          let temList = resData.map(item => {
            return {
              dictName: item.name,
              value: item.id,
              phone: item.phone,
            }
          })
          this.cellList.push(...temList)
        }
      } catch (error) {
        this.loading = false
        this.finished = true
        this.$toast.fail(error.message)
      }
    },
    confirm(data) {
      this.resolve({ ...data })
      this.showPicker = false
    },
    cancel() {
      this.reject('cancel')
      this.showPicker = false
    }
  }
}
</script>

<style>

</style>
