<!--用来模拟跳转的弹出层 * cellList 数据列表 * title 选择器标题 * multiple 是否多选 * max 最大个数限制-->
<!--multiple为true时有效 * min 最小个数限制 multiple为true时有效 -->
<!--* 对外广播confirm、change和cancel事件-->
<script>
import { Toast } from 'vant'

export default {
  props: {
    showPicker:{
      type: Boolean,
      default: true
    },
    position: {
      type: String,
      default: 'right'
    },
    cellList: {
      type: Array,
      default: function() {
        return []
      }
    },
    // 选择器标题
    title: {
      type: String,
      default: '请选择'
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 最大个数限制
    max: {
      type: [Number, String]
    },
    // 最小个数限制
    min: {
      type: [Number, String]
    },
    loading: {
      type: Boolean
    },
    finished: {
      type: Boolean
    },
  },
  data() {
    return {
      actives: [],
      newLoading: false
    }
  },
  computed: {
    // 筛选返回数据
    resultArray() {
      return this.cellList.filter((v, i) => this.actives.includes(i))
    },
    judgePc() {
      return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    },
  },
  watch: {
    loading: {
      immediate: true,
      handler(val) {
        this.newLoading = val
      }
    },
    'cellList.length'(length){
      if (!length){
        this.actives = []
      }
    },
    showPicker: {
      handler(val) {
        if (val) {
          this.onLoad()
        }
      }
    }
  },
  methods: {
    onLoad() {
      this.$emit('onload')
    },
    confirm(value) {
      if (this.multiple && this.min !== undefined && this.actives.length < this.min) {
        return Toast.fail(`必须选择${this.min}个以上的选项`)
      }
      this.$emit('confirm', this.multiple & this.min !== 1 && this.max !== 1 ? this.resultArray : this.resultArray[0])
    },
    onChange(item, index) {
      if (item.disabled) return
      if (this.multiple && this.min===1 && this.max===1){
        this.actives = [index]
      } else if (this.multiple) {
        // 多选
        if (this.actives.includes(index)) {
          const _i = this.actives.indexOf(index)
          this.actives.splice(_i, 1)
        } else {
          if (this.max !== undefined) {
            // 最大个数限制
            if (this.actives.length >= this.max) return
          }
          this.actives.push(index)
        }
      } else {
        // 单选
        this.actives.splice(0, 1, index)
        this.$emit('confirm', item)
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    isActive(index) {
      return this.actives.includes(index)
    },
    changeInput (value, roleCodes) {
      this.$emit('onload', value, roleCodes)
    }
  }
}
</script>
<template>
  <van-popup :value="showPicker" class="back-popup" :close-on-click-overlay="false" get-container="#app" :position="position" style="width: 85%;height: 100%;">
  <div>
    <div id="header-popup">
      <div class="opt" @click="cancel">
        <slot name="header-left">
          <van-icon name="arrow-left" size="18"></van-icon>
        </slot>
      </div>
      <div id="title">
        <slot name="header-title">{{title}}</slot>
      </div>
      <div v-if="multiple" class="opt" @click="confirm">
        <slot name="header-right">
          确定
        </slot>
      </div>
    </div>
    <slot name="tab"></slot>
    <slot name="search"></slot>
    <div>
      <slot name="content">
        <van-list
          v-model="newLoading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-cell v-for="(item, index) in cellList"
                    :key="index"
                    :class="{ active: isActive(index) }"
                    clickable
                    :center="true"
                    :label="item.desc || ''"
                    :value="item.phone"
                    border :title="item.dictName" @click="onChange(item,index)">
            <template #right-icon>
              <van-icon v-show="isActive(index)" name="success" size="22" color="#B9921A"></van-icon>
            </template>
          </van-cell>
        </van-list>
      </slot>
    </div>
  </div>
  </van-popup>
</template>
<style lang="less">
.back-popup {
  #header-popup {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    border-bottom: #f5f5f5 solid 1px;
    color: @black;
    background: @yellow;
    position: sticky;
    top: 0px;
    z-index: 100;
    .opt {
      position: absolute;
      top: 0;
      bottom: 0;
      line-height:  46px;
      padding: 0 10px;
      font-size: 17px;

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }

      .van-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }

  .van-cell {
    &.active {
      color: @yellow-text;
    }

    .van-icon {
      font-size: 17px;
      line-height: inherit;
    }
  }

  .van-cell:after {
    transform: none;
  }
}
</style>
