<!-- 线索跟进 -->
<template>
  <div>
    <van-form ref="clueFollowComponent" input-align="right" error-message-align="right" class="followInfo">
      <!-- 跟进方式 战败时不展示跟进方式-->
      <van-field
        v-if="!['2000045'].includes(clueDetail.state)"
        name="radio"
        :label="$t('跟进方式')"
        required
        input-align="right"
        error-message-align="right"
        :rules="[{required:true, message:$t('请选择跟进方式')}]">
        <template #input>
          <van-radio-group
            v-model="followRequest.followMode"
            :disabled="isShopReceiveUpdate"
            icon-size="32px"
            class="custom-radio-group-wrap"
            >
            <van-radio v-for="item in followModeList" :key="item.code"  :name="item.code" class="custom-radio-wrap" @click="changeFollowMode">
              <template #icon="props">
                <div class="custom-radio" :class="{ checked: props.checked, wechatCheck: props.checked }">{{item.name}}</div>
              </template>
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div v-if="appointmentList.length > 1" style="padding: 0 0px; width: 100%;" class="appointmentBox">
        <van-tabs v-model="appointmentIdSelectId">
          <van-tab v-for="(item, index) in appointmentList" :key="index" :name="item.id" :title="`预约信息${index+1}`" @click="changeAppointment(item)"></van-tab>
        </van-tabs>
      </div>
      <!-- 回访结果 无效跟进时展示 -->
      <van-field
        v-if="['2000020'].includes(clueDetail.state)"
        name="radio"
        :label="$t('回访结果')"
        required
        input-align="right"
        error-message-align="right"
        :rules="[{required:true, message:$t('请选择回访结果')}]">
        <template #input>
          <van-radio-group
            v-model="followRequest.visitResult"
            icon-size="32px"
            class="custom-radio-group-wrap"
            >
            <van-radio v-for="item in visitResultList" :key="item.code"  :name="item.code" class="custom-radio-wrap">
              <template #icon="props">
                <div class="custom-radio" :class="{ checked: props.checked, wechatCheck: props.checked }">{{item.name}}</div>
              </template>
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <template v-if="!isKeepInvalid">
        <!-- 跟进结果 1、无效跟进 默认不显示 选择了回访结果（且不能是保持无效） -->
        <van-field
          v-if="!invalidVisitResult"
          key="followResult"
          name="radio"
          :label="$t('跟进结果')"
          required
          :rules="[{required: followRequest.followResult ? false : true,message:$t('请选择跟进结果')}]"
          error-message-align="right"
          input-align="right">
          <template #input>
            <van-radio-group
              v-if="followUpResultList.length"
              v-model="followRequest.followResult"
              :disabled="isShopReceiveUpdate"
              icon-size="32px"
              class="custom-radio-group-wrap"
            >
              <van-radio v-for="item in followUpResultList" :key="item.dictCode" :name="item.dictCode" class="custom-radio-wrap">
                <template #icon="props">
                  <div class="custom-radio" :class="{ checked: props.checked, commonCheck: props.checked }" @click.stop="onChangeResult(item)">{{item.dictName}}</div>
                </template>
              </van-radio>
              <p v-if="followRequest.followResult == '1011001'" style="font-size: 12px;color: #eed484;margin-left: 12px;">{{ $t('注：新线索，联系客户未接通时选择') }}</p>
            </van-radio-group>
            <p v-else style="color:#969799;">请优先选择跟进方式</p>
          </template>
        </van-field>
        <!-- 店内接待 客户预约 -->
        <div v-if="getShopReceive">
          <van-field
            name="storeOfTime"
            :label="$t('进店时间')"
            :placeholder="$t('请选择')"
            right-icon="arrow"
            :value="followRequest.storeOfTime"
            readonly
            input-align="right"
            @click="selectDate('storeOfTime','time')"
            >
          </van-field>
          <van-field
            name="personCount"
            :label="$t('进店人数')"
            readonly
            input-align="right"
            :value="followRequest.personCount">
            <template #input>
              <van-stepper v-model="followRequest.personCount" max='99' integer/>
            </template>
          </van-field>
          <!-- <van-field
            :label="$t('关联进店客户')"
            right-icon="arrow"
            readonly
            @click="openRoom">
            <template #input>
              <span style="color: #b9921a">{{ roomSuccess }}</span>
            </template>
          </van-field> -->
          <van-field
            name="radio"
            :label="$t('是否参加活动')"
            error-message-align="right"
            :value="followRequest.isActivity"
            input-align="right">
            <template #input>
              <van-radio-group
                v-model="followRequest.isActivity"
                icon-size="32px"
                class="custom-radio-group-wrap"
              >
                <van-radio v-for="item in toShopActivity" :key="item.dictCode" :name="item.dictCode" class="custom-radio-wrap">
                  <template #icon="props">
                    <div class="custom-radio" :class="{ checked: props.checked, typeCheck: props.checked }">{{item.dictName}}</div>
                  </template>
                </van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <!-- 预约事项(客户预约时展示) -->
        <van-field
          v-if="showAppointmentTypeCode"
          key="appointmentTypeCode"
          name="radio"
          :label="$t('预约事项')"
          required
          :class="[appointmentDisabled ? 'appointment_disabled' : '']"
          :rules="[{required: followRequest.appointment.appointmentTypeCode ? false : true,message:'请选择预约事项'}]"
          error-message-align="right"
          input-align="right">
          <template #input>
            <van-radio-group
              v-model="followRequest.appointment.appointmentTypeCode"
              icon-size="32px"
              class="custom-radio-group-wrap">
              <van-radio v-for="item in appointmentTypeList" :key="item.dictCode" :name="item.dictCode" class="custom-radio-wrap">
                <template #icon="props">
                  <div class="custom-radio" :class="{ checked: props.checked, appointmentTypeCheck: props.checked }" @click.stop="onChangeAppoinment(item)">{{item.dictName}}</div>
                </template>
              </van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <!-- 预约到店时展示 非战败 -->
        <!-- 店内接待-继续跟进-不显示 -->
        <div v-if="followRequest.appointment.appointmentTypeCode === '2001002' && ((getShopReceive && followRequest.followResult !== '1011002') ||!getShopReceive) && followRequest.followResult !== '1011007'">
          <!-- 预约时间 -->
          <van-field
            key="appointmentTimeShow"
            v-model="followRequest.appointment.appointmentTimeShow"
            :class="[appointmentDisabled ? 'appointment_disabled' : '']"
            required
            :label="$t('预约时间')"
            :placeholder="$t('请选择')"
            :right-icon="appointmentDisabled ? '' : 'arrow'"
            readonly
            input-align="right"
            :rules="[{required:true}]"
            @click="selectCalendar(1)">
          </van-field>
        </div>
        <!-- 预约试驾时展示 -->
        <div v-if="followRequest.appointment.appointmentTypeCode === '2001001' && followRequest.followResult && followRequest.followResult!=='1011007'">
          <!-- 试驾场景 -->
          <van-field
            key="typeCode"
            name="radio"
            :label="$t('试驾场景')"
            required
            :class="[appointmentDisabled ? 'appointment_disabled' : '']"
            :rules="[{required: followRequest.appointment.typeCode ? false : true,message:$t('请选择')}]"
            error-message-align="right"
            input-align="right">
            <template #input>
              <van-radio-group
                v-if="driveTypeList.length"
                v-model="followRequest.appointment.typeCode"
                icon-size="32px"
                class="custom-radio-group-wrap"
              >
                <van-radio v-for="item in driveTypeList" :key="item.dictCode" :name="item.dictCode" class="custom-radio-wrap">
                  <template #icon="props">
                    <div class="custom-radio" :class="{ checked: props.checked, typeCheck: props.checked }" @click.stop="onChangeDrive(item)">{{item.dictName}}</div>
                  </template>
                </van-radio>
              </van-radio-group>
              <p v-else style="color:#969799;">{{ $t('请选择') }}</p>
            </template>
          </van-field>
          <!-- 试驾信息 到店试驾时展示 -->
          <van-field
            v-if="followRequest.appointment.typeCode==='2002001'"
            v-model="followRequest.appointment.vehicleName"
            :required="['1011002', '1011005'].includes(followRequest.followResult) ? false : true"
            :label="$t('试驾信息')"
            :placeholder="$t('请选择')"
            readonly
            :class="[appointmentDisabled ? 'appointment_disabled' : '', (!followRequest.appointment.vinCode && !followRequest.appointment.vehicleName) ? 'errorField' : '']"
            input-align="right"
            :right-icon="appointmentDisabled ? '' : 'arrow'"
            :rules="[{required: !['1011002', '1011005'].includes(followRequest.followResult)}]"
            @click="chooseScheduling">
            <template #input>
              <div class="schedulingBox">
                <div v-if="followRequest.appointment.appointmentStartTime" class="schedulingVehicle">
                  <div class="scheItem">{{ followRequest.appointment.vehicleName }}</div>
                  <div class="scheItem carNumber">{{ followRequest.appointment.carNumber }} </div>
                  <div class="scheItem">{{ followRequest.appointment.appointmentTimeShow }} </div>
                </div>
                <span v-else class="chooseBtn">{{ $t('排程') }}</span>
              </div>
            </template>
          </van-field>
          <!-- 试驾信息 上门试驾时展示 -->
          <template v-if="followRequest.appointment.typeCode==='2002002'">
            <van-field :label="$t('预约车型')"
              :value="followRequest | formatCarInfo"
              readonly
              required
              :right-icon="'arrow'"
              :placeholder="$t('请选择')"
              :rules="[{required:true}]"
              :class="[appointmentDisabled ? 'appointment_disabled' : '']"
              @click="selectCarModel('driveCarInfo')"/>
            <van-field v-model="followRequest.appointment.appointmentTimeShow"
              :label="$t('预约试驾时间')"
              readonly
              required
              :right-icon="'arrow'"
              input-align="right"
              :placeholder="$t('请选择')"
              :rules="[{required:true}]"
              :class="[appointmentDisabled ? 'appointment_disabled' : '']"
              @click="selectCalendar(2)"/>
              <AddressSystem ref="addressSystem"
                :label="$t('试驾地点')"
                :keys="addressKeys"
                :form-data="addressObj"
                :placeholder="$t('请选择')"
                :inputAlign="'right'"
                :isRequired="true"
                :rules="[{required:true}]"
                :isDisabled="appointmentDisabled"
                @change="onAddressChange">
              </AddressSystem>
            <van-field v-model="followRequest.appointment.driveAddress"
              :label="$t('详细地址')"
              required
              :placeholder="$t('请输入')"
              maxlength="50"
              :rules="[{required:true}]"
              :disabled="appointmentDisabled"
              :class="[appointmentDisabled ? 'appointment_disabled' : '']"/>
          </template>
          <!-- 试驾信息  集中试驾时展示 -->
          <template v-if="followRequest.appointment.typeCode==='2002003'">
            <van-field v-model="selectActivity.activityTitle"
              :label="$t('活动名称')"
              readonly
              required
              :right-icon="'arrow'"
              input-align="right"
              :placeholder="$t('请选择')"
              :rules="[{required:true}]"
              :class="[appointmentDisabled ? 'appointment_disabled' : '']"
              @click="onClickActivityTitle"
              />
            <van-field v-model="followRequest.seriesName"
              :label="$t('预约车系')"
              readonly
              required
              :right-icon="'arrow'"
              input-align="right"
              :placeholder="$t('请选择')"
              :rules="[{required:true}]"
              :class="[appointmentDisabled ? 'appointment_disabled' : '']"
              @click="onClickActivitySeries"
              />
            <van-field v-model="followRequest.appointment.appointmentTimeShow"
              :label="$t('预约试驾时间')"
              readonly
              required
              :right-icon="'arrow'"
              input-align="right"
              :placeholder="$t('请选择')"
              :rules="[{required:true}]"
              :class="[appointmentDisabled ? 'appointment_disabled' : '']"
              @click="selectCalendar(2)"/>
            <van-field :value="selectActivity | addressDisplay"
              :label="$t('试驾地点')"
              readonly
              required
              :right-icon="'arrow'"
              input-align="right"
              :placeholder="$t('请选择')"
              :rules="[{required:true}]"
              :class="[appointmentDisabled ? 'appointment_disabled' : '']"/>
          </template>
        </div>
        <!-- 顾问(继续跟进 客户预约时展示) -->
        <van-field
          v-if="showAssignUser"
          v-model="followRequest.assignUserName"
          :required="followRequest.followResult === '1011004'"
          :class="[appointmentDisabled ? 'appointment_disabled' : '']"
          :label="((followRequest.followResult === '1011002' && !appointmentIdSelectId) || followRequest.followResult === '1011005') ? $t('转交顾问') : followRequest.followResult === '1011035' ? $t('销售顾问') : $t('接待顾问')"
          :placeholder="$t('请选择')"
          :right-icon="appointmentDisabled || assignUserDefeault ? '' : 'arrow'"
          input-align="right"
          readonly
          :rules="[{required: followRequest.followResult === '1011004' ? true : false}]"
          @click="onChangeSale"
        />
        <!-- 意向信息 已试驾 意向信息必填 -->
        <van-field
          v-if="followRequest.appointment.appointmentTypeCode != '2001001' && showCarInfo"
          ref="carInfo"
          key="carInfo"
          :label="$t('意向车型')"
          :placeholder="$t('请选择意向信息')"
          :value="followRequest.carInfo | formatCarInfo"
          right-icon="arrow"
          type="textarea"
          rows="1"
          autosize
          readonly
          required
          :rules="[{required:true}]"
          :error-message="carInfoErrMsg"
          @click="selectCarModel('carInfo')"
        />
        <!-- <试驾意向等级 -->
        <van-field
          v-if="showPlanDriveLevel"
          key="tryDriveLevelName"
          :label="$t('试驾意向等级')"
          :placeholder="$t('请选择')"
          :value="followRequest.tryDriveLevelName"
          right-icon="arrow"
          readonly
          input-align="right"
          required
          :rules="[{required:true}]"
          @click="selectField('tryDriveLevel', 'tryDriveLevelName', 1012, false)">
        </van-field>
        <!-- 非无效、战败 展示性别 必填 -->
        <van-field
          v-if="showGender"
          key="userSex"
          name="radio"
          :label="$t('性别')"
          required
          input-align="right"
          error-message-align="right"
          :rules="[{required:true, message:$t('请选择性别')}]">
          <template #input>
            <van-radio-group
              v-model="followRequest.userSex"
              icon-size="32px"
              class="custom-radio-group-wrap">
              <van-radio v-for="item in getQueryLabels('userSex')" :key="item.code" :name="item.code" class="custom-radio-wrap">
                <template #icon="props">
                  <div class="custom-radio" :class="{ checked: props.checked, wechatCheck: props.checked }">{{item.name}}</div>
                </template>
              </van-radio>
            </van-radio-group>
          </template>
        </van-field>
      <!-- 试驾人数 只要显示性别，则需要显示试驾人数，输入正整数  已试驾testDriveMark线索，必填  -->
      <van-field
        v-if="showGender"
        name="drivePersonCount"
        v-model="followRequest.drivePersonCount"
        :label="$t('试驾人数')"
        :placeholder="$t('请输入')"
        input-align="right"
        type="digit"
        maxlength="2"
        :required="testDriveMark"
        :value="followRequest.drivePersonCount"
        :rules="[{required: testDriveMark},{ validator: isPositiveInteger, message: '请输入正整数' }]">
        <!-- <template #input>
          <van-stepper v-model="followRequest.drivePersonCount" max='99' integer/>
        </template> -->
      </van-field>
      <!-- 客户顾虑 已试驾线索，必填   -->
      <van-field
        v-if="showGender"
        name="concern"
        :label="$t('客户顾虑')"
        :placeholder="$t('请选择')"
        :value="getCMValue(followRequest.concern)"
        input-align="right"
        error-message-align="right"
        right-icon="arrow"
        type="textarea"
        rows="1"
        autosize
        :required="testDriveMark"
        :rules="testDriveMark ? [{required: true}] : []"
        @click="openPicker">
      </van-field>
      <!-- 客户顾虑补充 -->
      <van-field
        v-if="showGender"
        v-model="followRequest.concernRemark"
        :label="$t('客户顾虑补充')"
        :placeholder="$t('请输入客户顾虑补充')"
        input-align="right"
        rows="3"
        autosize
        type="textarea"
        maxlength="500"
        show-word-limit>
      </van-field>
        <!-- 是否了解电车 店内接待或者已经试驾过的线索 非战败 无效 -->
        <van-field
          v-if="showKnowEv"
          key="knowEv"
          name="radio"
          :label="$t('是否了解电车')"
          required
          input-align="right"
          error-message-align="right"
          :rules="[{required:true, message:$t('请选择')}]">
          <template #input>
            <van-radio-group
              v-model="followRequest.knowEv"
              icon-size="32px"
              class="custom-radio-group-wrap">
              <van-radio v-for="item in getQueryLabels('knowEv')" :key="item.code" :name="item.code" class="custom-radio-wrap">
                <template #icon="props">
                  <div class="custom-radio" :class="{ checked: props.checked, wechatCheck: props.checked }">{{item.name}}</div>
                </template>
              </van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <!-- 购车意向评级(继续跟进、客户预约时展示)-->
        <div v-if="['1011002', '1011004', '1011005', '1011006','1011035'].includes(followRequest.followResult)" class="leadLevelInfo">
          <van-collapse v-model="activeCollapse">
            <van-collapse-item class="form-collapse-item" :lazy-render="false" :title="$t('购车意向评级')" name="1">
              <LeadLevel ref="leadLevel" :required="requireMark" :drivingTime="followRequest.drivingTime" :leadLevelInfoFill="leadLevelInfoFill" @refreshLevel="(val) => followRequest.leadLevel = val"></LeadLevel>
            </van-collapse-item>
          </van-collapse>
          <van-field
            v-model="followRequest.leadLevel"
            :label="$t('购车意向评级')"
            :placeholder="$t('暂无评级结果')"
            readonly
            clickable
            input-align="right">
          </van-field>    
        </div>
        <!-- 联系结果(待跟进时显示) / 无效原因 / 战败原因  -->
        <van-field
          v-if="showFailCode.show"
          key="sysRemark"
          v-model="followRequest.sysRemark"
          required
          :label="showFailCode.info.label"
          :placeholder="showFailCode.info.placeholder"
          right-icon="arrow"
          readonly
          input-align="right"
          :rules="[{required:true}]"
          @click="selectField('failCode', 'sysRemark', showFailCode.info.key, false)">
          <!-- <template #input>
            <div v-if="followRequest.sysRemark">{{ followRequest.sysRemark }}</div>
            <div v-else style="color:#ee0a24;font-size: 12px;">{{ showFailCode.info.placeholder }}</div>
            <Labels v-if="followRequest.followResult ==='1011007' && driveTimes" :data="[driveTimes]"></Labels>
          </template> -->
        </van-field>
        <!-- 战败原因补充 -->
        <van-field
          v-if="supplement"
          v-model="followRequest.sysRemarkSupplement"
          :label="$t('战败原因补充')"
          :placeholder="$t('请输入战败补充原因')"
          input-align="right"
          :required="supplementRequired"
          maxlength="10"
          show-word-limit
          :rules="supplementRequired ? [{required: true}] : []">
        </van-field>
      </template>
        <!-- 跟进内容 -->
      <van-cell :title="$t('跟进内容')" required>
        <template #right-icon>
          <btn-voice-input @input="(value) => (followRequest.followRemark = followRequest.followRemark + value.slice(0, 200))" />
        </template>
      </van-cell>
      <van-field
        v-model.trim="followRequest.followRemark"
        :border="false"
        rows="3"
        autosize
        type="textarea"
        maxlength="200"
        :placeholder="['1011034','1011035'].includes(followRequest.followResult) || followRemarkNotTenLength ? $t('请输入'): $t('请输入不少于10个字的跟进内容')"
        show-word-limit
        :error-message="followRemarkErrMsg"
        :rules="[{required:true}, { validator: followRemarkValidator }]"
      />
      <van-cell v-if="['1011001', '1011002', '1011003', '1011007'].includes(followRequest.followResult)">
        <div style="text-align: right; margin-right: -10px;">
          <van-tag v-for="(item, index) in shortcuts" :key="index" text-color="rgba(13, 23, 26, 0.45)" color="#F5F5F5" style="padding: 2px 8px; margin-right: 10px;" size="medium" round type="primary" @click="onClickShortcuts(item)">{{ item.name }}</van-tag>
        </div>
      </van-cell>
      <!-- 下次跟进时间 -->
      <van-field
        v-if="!['1011003', '1011007','1011034','1011035'].includes(followRequest.followResult) && !orderStatus.includes(clueDetail.state) && !isKeepInvalid"
        :value="followRequest.planTime | timeFormat"
        required
        :label="$t('下次跟进时间')"
        :placeholder="$t('请选择下次跟进时间')"
        right-icon="arrow"
        readonly
        input-align="right"
        :rules="[{required:true}]"
        @click="selecNextFollowTime">
      </van-field>
      <van-cell v-if="!showMoreInfo && !isKeepInvalid">
        <template #default>
          <div class="complete" @click="improveUser">
            {{ $t("完善客户信息") }}
          </div>
        </template>
      </van-cell>
    </van-form>
    <div v-show="showMoreInfo" class="moreInfo">
      <van-collapse v-model="moreInfoCollapse">
        <van-collapse-item v-for="(item, index) in infos" :key="index" class="form-collapse-item" :title="item.name" :name="index+1">
          <EditInformation :ref="`editInformation_${index}`" :title="''" :fromModule="'createClue'" :dictionaries="dictionaries" :details="item.items" :leadId="''" :showBtn="false" :showConcern="showGender"></EditInformation>
          <van-cell v-if="index + 1 == infos.length">
            <template #default>
              <div class="complete" @click="collapse">
                {{ $t("收起") }}
              </div>
            </template>
          </van-cell>
        </van-collapse-item>
      </van-collapse>
    </div>
    <van-popup v-model="showCalendar" style="width:100%;max-width: 500px;height:100%">
      <div  class="popupNav">
        <div class="opt" @click="showCalendar=false">
          <slot name="header-left">
            <van-icon name="arrow-left" size="18"></van-icon>
          </slot>
        </div>
        <!-- <div id="title">
          <slot name="header-title">{{ title }}</slot>
        </div> -->
        <div class="opt" @click="handleDateTimeConfirm2">
          <slot name="header-right"> {{ $t('确认') }} </slot>
        </div> 
      </div>
      <!-- 上门试驾和集中试驾 预约时间调整为 9:00 - 24：00  -->
      <calendarDateVue v-if="showCalendar" ref="calendarDate" :isRemove="false"  :date="appointmentTime" 
        :minDate="getMinDate" 
        :maxDate="getMaxDate"
        :startRange="['2002002','2002003'].includes(followRequest.appointment.typeCode)?['09:00','23:30']:['00:00','23:50']"
        :endRange="['2002002','2002003'].includes(followRequest.appointment.typeCode)?['09:00','23:30']:['00:00','23:50']"
        :timeInterval="['2002002','2002003'].includes(followRequest.appointment.typeCode)?30:10" 
        @change="(time)=>{appointmentTime=time}"/>
    </van-popup>
    <!-- 排程信息 -->
    <van-popup v-model="showScheduling" style="width:100%; max-width: 500px;height:100%">
      <testDriveSchedule v-if="showScheduling" :fromClueFollow="true" :data="followRequest.appointment" @confirm="confirmScheduling" @close="showScheduling=false"/>
    </van-popup>
    <van-calendar v-model="showNextFollowTime" :first-day-of-week="1" :title="$t('下次跟进时间')" :show-confirm="false" :min-date="planeMinDate" :max-date="planeMaxDate" :default-date="defaultValue" @confirm="dateConfirm"/>
    <!-- 集中试驾活动 -->
    <van-action-sheet v-model="isShowActivityList" :actions="activityList" @select="onActivitySelect" />
    <!-- 集中试驾车系 -->
    <van-action-sheet v-model="isShowActivitySeries" :actions="activitySeriesList" @select="onActivitySeriesSelect" />
    <Room v-if="roomDialogShow" @close="closeRoomDialog"></Room>
    <!-- 客户顾虑 -->
    <van-popup v-model="multipleCascadering" style="width:100%; height:100%">
      <Cascader v-if="multipleCascadering" :list="concernList" :title="$t('客户顾虑')" :details="followRequest.concern" @close="multipleCascadering = false" @ok="handleCompetingOK"></Cascader>
    </van-popup>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { groupBy, isString } from 'lodash'
import selectSalesperson from '@/components/select-salesperson'
import { mapGetters } from 'vuex'
import selectDate from '@/components/date-picker'
import commonSelector from '@/components/common-selector'
import BtnVoiceInput from '@/components/btn-voice-input'
import LeadLevel from './leadLevel'
import { validate } from '@/utils/validate'
import baseDataServices from '@/services/baseDataServices'
import CommonDatePicker from '@/components/v2/system/CommonDatePicker/index.vue'
import selectCar from '@/components/select-car'
import clueServices from '@/services/clueServices'
import { dateFormat,padTime } from '@/utils'
import CellPicker from '@/components/cell-picker/index.vue'
import AddressSystem from '@/components/addressSystem.vue'
import Labels from '@/components/card-view/label.vue'
import calendarDateVue from '@/modules/appointment/component/calendarDate.vue'
import CarManageServices from '@/services/carManageService.js'
import moment from 'moment'
import { getNearHour } from '@/utils/index.js'
import EditInformation from '../../clue/component/tags/EditInformation.vue'
import loading from '@/utils/loading'
import Room from '@/components/room'
import testDriveSchedule from '@/modules/salesAssistant/testDriveSchedule.vue'
import selectDefeatReasons from '@/components/select-defeat-reasons'
// Components
import Cascader from '@/modules/clue/component/tags/Cascader.vue'
import { last, isArray } from 'lodash'
import { i18n } from '@/main'

export default {
  name: 'followInfo',
  filters: {
    formatCarInfo(info) {
      const { seriesName, modelName } = info
      let display = ''
      seriesName && (display += ` ${seriesName}`)
      modelName && (display += ` ${modelName}`)
      return Object.keys(info).length === 0
        ? null
        : display
    },
    addressDisplay(val){
      if (Object.keys(val).length){
        const { activityProvinceName,activityCityName,activityAreaName,activityAddress } = val
        return `${activityProvinceName}${activityCityName}${activityAreaName}${activityAddress}`
      } else {
        return ''
      }
    },
    timeFormat(val){
      if (!val) return ''
      return moment(val).format('YYYY-MM-DD')
    }
  },
  components: {
    BtnVoiceInput,
    LeadLevel,
    CommonDatePicker,
    CellPicker,
    AddressSystem,
    Labels,
    calendarDateVue,
    EditInformation,
    Room,
    testDriveSchedule,
    Cascader
  },
  props: {
    clueDetail: {
      type: Object,
      default:() => ({})
    },
    from: {
      type: String,
      default:() => 'follow'
    },
  },
  data() {
    const that = this
    return {
      shortcutsSelected: false,
      activeCollapse: ['1'],
      moreInfoCollapse: [1,2,3],
      requireMark: false,
      validate,
      followRequest: {
        followMode: '', // 跟进方式
        visitResult: '', // 回访结果
        followResult: '', // 跟进结果
        failCode: '', // 联系结果、战败、无效
        sysRemark: '', // 待确认、战败、无效、详情
        sysRemarkSupplement: '', // 战败原因补充
        followRemark: '', // 跟进内容
        planTime: '', // 下次跟进时间
        leadLevel: '', // 线索级别
        // planDriveTime: '', // 预计试驾时间
        assignUserId: '', // 分配顾问id
        assignUserName: '', // 分配顾问姓名
        appointment: {
          appointmentEndTime: '',
          appointmentStartTime: '',
          appointmentTypeCode: '',
          cancelReason: '',
          carNumber: '',
          typeCode: '',
          vinCode: '',
          vehicleCode: '',
          vehicleName: '',
          appointmentTimeShow: '',
          id: '',
          driveProvinceCode:'', // 上门试驾省code
          driveProvinceName:'', // 上门试驾省名称
          driveCityCode:'', // 上门试驾市code
          driveCityName:'', // 上门试驾市名称
          driveDistrictCode:'', // 上门试驾区code
          driveDistrictName:'', // 上门试驾区名称
          driveAddress:'', // 上门试驾详细地址
          activityTitle: '', // 集中试驾活动名称
          testDriveActivityId: '', // 集中试驾活动id
        },
        carInfo: {}, // 意向信息
        storeOfTime:'',
        personCount: 1,
        isActivity:'',
        modelCode:'', // 上门试驾车型
        modelName: '',
        seriesCode:'', // 上门试驾车系
        seriesName: '',
        tryDriveLevel: '', // 试驾意向等级 code
        tryDriveLevelName: '', // 试驾意向等级 name
        userSex: '', // 性别
        knowEv: '', // 是否了解电车
        drivePersonCount: '', // 试驾人数
        concern: [], // 客户顾虑id
        concernRemark: '', // 客户顾虑补充
        // drivePersonCount: '', // 选配信息
      },
      minDate: new Date(),
      planeMinDate: new Date(),
      planeMaxDate: new Date(dayjs().add(60, 'days')),
      maxDate: new Date(2099, 0, 31),
      showCalendar: false,
      carInfoErrMsg: '',
      showScheduling: false,
      appointmentList: [], // 预约试驾单列表 初始化一个 是为了兼容新增时候的场景 -恼火-
      appointmentSelectItem: {}, // 用于回填预约试驾单的数据
      appointmentIdSelectId: '',
      leadLevelInfoFill: {}, // 用于回填
      showNextFollowTime: false,
      activeTab: 0,
      defaultValue: null,
      assignUserDefeault: false, // 默认顾问填充 有了顾问就不允许再修改
      addressObj:{},
      addressKeys:{
        cityCode:'driveCityCode',
        city:'driveCityName',
        provinceCode:'driveProvinceCode',
        province:'driveProvinceName',
        countyCode:'driveDistrictCode',
        county:'driveDistrictName',
      },
      driveCarInfo:{},
      activityList: [], // 集中试驾活动列表
      isShowActivityList: false,
      selectActivity:{}, // 选中的集中试驾活动
      isShowActivitySeries: false,
      activitySeriesList: [], // 集中试驾车系列表
      activityDate: '',
      getMinDate: dayjs().toDate(),
      getMaxDate: dayjs().add(12,'month').toDate(),
      appointmentTime:[],
      orderStatus:['2000050','2000055','2000060','2000065'], // 已签单的状态
      showMoreInfo: false, // 展示完善信息
      infos: [], // 当前线索标签
      dictionaries: [], // 所有标签字典数据
      roomDialogShow: false,
      roomSuccess: '',
      followRemarkErrMsg: '',
      supplement: false, // 战败原因补充
      supplementRequired: false, // 战败原因补充是否必填

      multipleCascadering: false
    }
  },
  computed: {
    ...mapGetters(['dictHash', 'userInfo', 'templateClueInfo']),
    shortcuts() {
      return this.$store.getters.getDictHash(1018)
    },
    followRemarkNotTenLength() {
      return ['1011001', '1011002', '1011003', '1011007'].includes(this.followRequest.followResult) && this.shortcutsSelected
    },
    toShopActivity(){
      return [
        { dictName: this.$t('是'), dictCode: 1 },
        { dictName: this.$t('否'), dictCode: 0 },
      ]
    },
    followModeList() {
      const { $store, followRequest,$route, } = this
      let list =$store.getters.getDictHash(1010)
      const task = [
        // 取消预约不显示店内接待
        // followRequest.followResult === '1011005',
        // 从预约到店页面（取消预约、修改预约）过来，不显示店内接待
        followRequest.followResult === '1011006' && $route.query.appointmentId && followRequest.appointment.appointmentTypeCode === '2001002'
      ]
      if ((task.findIndex(item=>item)) !== -1){
        list = list.filter(({ code })=> !(['1010003'].includes(code)))
      }
      return list
    },
    // 回访结果list
    visitResultList() {
      const list = this.$store.getters.getDictHash(1019)
      return list.filter(({ code }) => ['1019001','1019002'].includes(code))
    },
    currentPer() {
      const { enumeration } = this.$store.getters
      return enumeration[this.appointmentIdSelectId ? 'hasAppoinment' : 'noAppointment'][this.clueDetail?.state || 1000000]
    },
    // 跟进结果
    followUpResultList() {
      const temp = ((( this.dictHash || {} ) [1011]) || []).map((item) => ({ ...item, dictName: item.name, dictCode: item.code }))
      let list = []
      // 因为要按currentPer的顺序展示才这样写
      this.currentPer.forEach(code => {
        const target = temp.find(e=>e.dictCode===code)
        target&&list.push(target)
      })
      if (this.getShopReceive){
        // 店内接待只有 继续跟进 客户预约 修改预约
        list = list.filter(({ dictCode })=> ['1011002', '1011004','1011006'].includes(dictCode))
        // 店内接待 且是预约到店的 不允许修改预约
        if (this.appointmentIdSelectId && this.followRequest.appointment.appointmentTypeCode == '2001002') { 
          list = list.filter(({ dictCode })=> '1011006' != dictCode)
        }
      }
      // 如果有预约试驾单的情况
      if (this.appointmentIdSelectId && this.appointmentSelectItem?.id) {
        const { status, appointmentTypeCode } = this.appointmentSelectItem // status 0：已取消、1：待确认、2：已确认、3：已完成
        if (appointmentTypeCode === '2001001') { // 预约试驾
          if (status == 1) { // 取消预约/修改预约/战败
            // list = list.filter(({ dictCode }) => ['1011005', '1011006', '1011007'].includes(dictCode))
            list = list.filter(({ dictCode }) => ['1011002', '1011005', '1011006', '1011007'].includes(dictCode))
          }
          if (status == 2) { // 继续跟进/取消预约/修改预约/战败
            list = list.filter(({ dictCode }) => ['1011002', '1011005', '1011006', '1011007'].includes(dictCode))
          }
        } else if (appointmentTypeCode === '2001002') { // 预约到店 继续跟进/取消预约/修改预约/战败
          list = list.filter(({ dictCode }) => ['1011002', '1011005', '1011006','1011007'].includes(dictCode))
        }
      }
      // this.$route.query.selfStatus === meet  确认出席（只能修改试驾和继续跟进,不显示取消预约）
      if (this.$route.query.selfStatus === 'meet' && this.appointmentIdSelectId){
        list = list.filter(({ dictCode }) =>'1011005' != dictCode)
      }
      // 判断线索的有效性 0待定  1有效
      // 有效:  继续跟进&客户预约&战败
      // 无效:  待定&继续跟进&客户预约&无效
      if (this.clueDetail.validStatus === 1) {
        list = list.filter(({ dictCode }) => !['1011001','1011003'].includes(dictCode))
      }
      if (this.clueDetail.validStatus === 0) {
        list = list.filter(({ dictCode }) => !['1011007'].includes(dictCode))
      }
      return list
    },
    // 判断 是否展示联系结果 无效 战败
    showFailCode() {
      const obj = {
        show: ['1011001', '1011003', '1011007'].includes(this.followRequest.followResult),
        info: {
          '1011001': {
            label: this.$t('联系结果'),
            key: 1015,
            placeholder: this.$t('请选择'),
          },
          '1011003': {
            label: this.$t('无效原因'),
            key: 'invalidReason',
            placeholder: this.$t('请选择无效原因'),
          },
          '1011007': {
            label: this.$t('战败原因'),
            key: 'defeatReason',
            placeholder: this.$t('请选择战败原因'),
          },
        }[this.followRequest.followResult],
      }
      return obj
    },
    // 预约类型
    appointmentTypeList() {
      return [
        { dictCode: '2001002', dictName: this.$t('预约到店') },
        { dictCode: '2001001', dictName: this.$t('预约试驾') },
      ]
    },
    // 试驾场景
    driveTypeList() {
      return [
        { dictCode: '2002001', dictName: this.$t('到店试驾') },
        { dictCode: '2002002', dictName: this.$t('上门试驾') },
        { dictCode: '2002003', dictName: this.$t('集中试驾') },
      ]
    },
    // 是否展示预约事项
    showAppointmentTypeCode() {
      // 预约的继续跟进 也会展示预约事项
      return ['1011004', '1011005', '1011006'].includes(this.followRequest.followResult) || (this.followRequest.followResult === '1011002' && this.appointmentIdSelectId && !this.getShopReceive)
    },
    // 是否展示跟进顾问
    showAssignUser() {
      const { followResult, visitResult } = this.followRequest
      const { state } = this.clueDetail
      // 战败并且跟进结果为‘激活’时展示
      if (state==='2000045'&&followResult==='1011035') return true
      // 无效且激活的时候
      if (state==='2000020'&&visitResult==='1019002') return true
      // 店内接待的【继续跟进、客户预约, 修改预约】不显示 && dcc阶段才会展示
      return ((this.getShopReceive && !(['1011002','1011004','1011006'].includes(followResult))) || !this.getShopReceive) 
      && this.showCarInfo 
      && (this.clueDetail.followUserRoleCode ? this.clueDetail.followUserRoleCode == 1014001 : true)
    },
    // 是否显示意向信息
    showCarInfo() {
      const { followResult } = this.followRequest
      return ['1011002', '1011004', '1011005', '1011006'].includes(followResult)
    },
    forShowCarInfo() {
      return this.showCarInfo && this.followRequest.appointment.appointmentTypeCode != '2001001'
    },
    // 是否展示预计试驾时间
    showPlanDriveTime() {
      // 普通的继续跟进 || 取消预约（预约到店 或者预约试驾）
      const { followResult,drivingTime } = this.followRequest
      const mark = [
        followResult === '1011002' && (!this.appointmentIdSelectId && !drivingTime),
        followResult === '1011005'&& !drivingTime,
      ]
      return mark.some((i)=>i)
    },
    // 店内接待
    getShopReceive() {
      const { followMode } = this.followRequest
      return followMode && followMode === '1010003'
    },
    // 禁止修改预约信息
    appointmentDisabled() {
      // 如果当前是预约跟进 且选择了继续跟进 或者取消预约
      return !!(this.appointmentIdSelectId && ['1011002', '1011005'].includes(this.followRequest.followResult))
    },
    // 到店接待过来的，不能修改跟进方式和跟进结果
    isShopReceiveUpdate() {
      const { followMode,followResult } = this.$route.query
      return followMode === '1010003' && followResult === '1011002'
    },
    // 战败标签
    defeatTags() {
      const { labels } = this.followRequest
      const str = labels.find(item=>item.includes('已试驾'))
      return str ? [str] : []
    },
    // 是否展示试驾意向等级
    showPlanDriveLevel() {
      // 非战败 无效 待跟进 且未试驾
      const { followResult,drivingTime } = this.followRequest
      return followResult && !['1011001','1011003','1011007','1011034'].includes(followResult) && !drivingTime
    },
    // 是否展示性别 非战败、无效
    showGender() {
      const { followResult } = this.followRequest
      return ['1011002','1011004', '1011005', '1011006'].includes(followResult)
    },
    // 是否已试驾
    testDriveMark() {
      const { drivingTime } = this.clueDetail
      return !!drivingTime
    },
    // 跟进信息里面展示 是否了解电车
    showKnowEv() {
      const { followResult } = this.followRequest
      return (this.testDriveMark || this.getShopReceive) && !['1011003', '1011007'].includes(followResult)
    },
    // 线索无效 且 还未选择回访结果
    invalidVisitResult() {
      const { state } = this.clueDetail
      return state === '2000020' && !this.followRequest.visitResult
    },
    // 回访结果是否是保持无效
    isKeepInvalid() {
      return this.followRequest.visitResult === '1019001'
    },
    getLangIsZH() {
      const lang = i18n.locale
      return lang.includes('zh')
    }
  },
  watch: {
    clueDetail: {
      handler(val) {
        const {
          leadLevel,
          seriesCode,
          seriesName,
          modelCode,
          modelName,
          appointments,
          drivingTime, // 试驾时间
          labels,
          tryDriveLevel,
          driveTimes,
          userSex='',
          drivePersonCount,
          concern,
          concernRemark,
          detail={}
        } = val
        const carInfo = {
          seriesCode,
          seriesName,
          modelCode,
          modelName,
        }
        const params = {
          carInfo,
          leadLevel,
          drivingTime,
          labels,
          tryDriveLevel,
          driveTimes,
          userSex: String(userSex) || '',
          drivePersonCount,
          concern,
          concernRemark,
          knowEv: detail?.leadLabel?.knowEv
        }
        // 有试驾时间，购车意向评级则默认展开
        if (drivingTime) {
          this.activeCollapse = ['1']
          this.requireMark = true
        } else {
          this.activeCollapse = []
        }
        this.followRequest = Object.assign(this.followRequest, params, { ...params.carInfo })
        this.followRequest.tryDriveLevelName = this.getNameByDictcode(1012, tryDriveLevel, 'name')
        if (appointments && appointments.filter(({ status }) => status !=9 ).length > 0) this.appointmentList = appointments
        this.appointmentIdSelectId = this.appointmentList[0]?.id || ''
      },
      immediate: true
    },
    showAssignUser: {
      handler(val) {
        if (val) {
          const { followUserName, followUserId,state } = this.clueDetail
          const { id, name, isSalesMan, isManager } = this.userInfo
          const { followResult, visitResult } = this.followRequest

          this.followRequest.assignUserName = isSalesMan ? (followUserName || name) : ''
          this.followRequest.assignUserId = isSalesMan ? (followUserId || id) : ''
          // 战败并且跟进结果为‘激活’时,允许选择其他销售顾问
          if (state==='2000045'&&followResult==='1011035'){
            return
          }
          // 无效且回访结果为‘激活’的时候，允许选择其他顾问
          if (state==='2000020'&&visitResult==='1019002'){
            return
          }
          if (this.followRequest.assignUserId && isSalesMan && !isManager) this.assignUserDefeault = true
        } else {
          this.followRequest.assignUserId = ''
          this.followRequest.assignUserName = ''
          this.assignUserDefeault = false
        }
      }
    },
    appointmentIdSelectId: {
      handler(val) {
        this.appointmentSelectItem = this.appointmentList.find(({ id }) => id == val)
        // 如果有预约信息 将预约数据做回填
        if (val) {
          this.backFill()
        } else {
          this.followRequest.appointment = this.$options.data().followRequest.appointment
        }
      },
      immediate: true
    },
    'followRequest.followMode': {
      handler(val, oldVal) {
        // 如果是店内接待的 需要排除掉预约到店的预约单数据 重新计算当前预约单数组appointments
        // 把之前默认赋值的数据重置 有点鸡肋
        this.appointmentIdSelectId = ''
        this.appointmentList = []
        this.requireMark = false
        // 重置之前默认赋值的数据
        let { appointments, drivingTime } = this.clueDetail
        if (drivingTime) {
          this.activeCollapse = ['1']
          this.requireMark = true
        } else {
          this.activeCollapse = []
        }
        appointments = appointments.filter(({ status }) => status !== 9 )
        if (val === '1010003') {
          appointments = appointments.filter(({ appointmentTypeCode }) => appointmentTypeCode !== '2001002')
        }
        if (appointments && appointments.length > 0) this.appointmentList = appointments
        this.appointmentIdSelectId = this.appointmentList[0]?.id || ''
        this.changeShow('followMode', val, oldVal)
      },
      immediate: true
    },
    'followRequest.followResult': {
      handler(val) {
        // 变更跟进结果的时候 改变更多信息内标签的显示项
        this.changeTags(val)
        this.supplement = false
        this.supplementRequired = false
        this.followRequest.sysRemarkSupplement = ''
        // 如果选择待跟进 下次跟进时间默认三天（接口获取）
        if (val === '1011001') this.getPlaneTime()
        this.changeShow('followResult')
        this.$emit('changeFollowResult',this.followRequest.followResult)
        const { detail, isDriveOption, carOrderMaterial } = this.clueDetail
        if (detail && !['1011003', '1011007'].includes(val)) {
          const { planBuyTime, payType, coMakers, partnerTry,competitive,competitiveFocus } = detail
          this.leadLevelInfoFill = {
            planBuyTime,
            payType,
            coMakers,
            partnerTry,
            competitive: competitive || [],
            competitiveFocus,
            isDriveOption,
            carOrderMaterial,
          }
        }
        // 取消预约 或者预约继续跟进的时候 默认回填上一次的预约信息
        if (this.appointmentIdSelectId && ['1011002', '1011005'].includes(val)) {
          this.backFill()
        }
        if (val === '1011001') {
          this.followRequest.userSex = ''
          this.followRequest.seriesCode = ''
          this.followRequest.seriesName = ''
          this.followRequest.modelCode = ''
          this.followRequest.modelName = ''
          this.followRequest.tryDriveLevel = ''
          this.followRequest.tryDriveLevelName = ''
          this.followRequest.carInfo = {}
        }
      },
      immediate: true
    },
    'followRequest.appointment.appointmentTypeCode': {
      handler() {
        this.changeShow('appointmentTypeCode')
      }
    },
    'followRequest.appointment.typeCode': {
      handler(val,oldVal) {
        this.$emit('changeTypeCode', val)
        this.changeShow('typeCode')
        // 选择了集中试驾，需要获取集中试驾活动列表
        if (val==='2002003') {
          this.getActivity()
          // 判断活动开始时间是否晚于当前时间，是则默认选择活动第一天  不是则默认选中今天
          if (this.selectActivity.activityTitle){
            const { activityStartTime } = this.selectActivity
            if (!this.followRequest.appointment.appointmentStartTime&&!this.followRequest.appointment.appointmentEndTime&&!dayjs(activityStartTime).isBefore(dayjs(),'minute')){
              this.followRequest.appointment.appointmentStartTime = moment(activityStartTime).format('YYYY-MM-DD')+' 00:00:00'
              this.followRequest.appointment.appointmentEndTime = moment(activityStartTime).format('YYYY-MM-DD')+' 00:30:00'
            }
          }
        } else {
          // 非集中试驾时，将试驾活动清空，以便清除时间范围的限制
          this.$set(this,'selectActivity',{})
          
        }
        // 集中试驾切换到其他试驾场景，需要将日历的日期置为今天
        // 如果当前跟进结果是继续跟进 或者取消预约 重置预约排程数据
        if (oldVal==='2002003'&&val!=='2002003'&&this.followRequest.followResult==='1011006'){
          this.followRequest.appointment.appointmentStartTime = ''
          this.followRequest.appointment.appointmentEndTime = ''
          this.followRequest.appointment.appointmentTimeShow = ''
        }
      },
      immediate:true
    },
    'followRequest.leadLevel': {
      handler() {
        this.getPlaneTime()
      },
      immediate: true
    },
    'followRequest.tryDriveLevel' : {
      handler(val) {
        val && this.getPlaneTime()
      }
    },
    templateClueInfo: {
      handler(val) {
        const { seriesCode, seriesName, modelCode, modelName } = val
        this.followRequest.carInfo = {
          seriesCode,
          seriesName,
          modelCode,
          modelName
        }
      },
    },
    // 集中试驾活动
    selectActivity:{
      handler(val){
        // 查询关联的车系
        if (Object.keys(val).length){
          const { id,activityTitle,activityProvinceName,activityCityName,activityAreaName,activityAddress,activityProvinceCode,activityCityCode,activityAreaCode,activityStartTime } = val
          // 绑定id和地址
          this.getSeriesByActivity(id)
          this.followRequest.appointment.testDriveActivityId = id
          this.followRequest.appointment.activityTitle = activityTitle
          this.followRequest.appointment.driveProvinceName = activityProvinceName
          this.followRequest.appointment.driveCityName = activityCityName
          this.followRequest.appointment.driveDistrictName = activityAreaName
          this.followRequest.appointment.driveAddress = activityAddress
          this.followRequest.appointment.driveProvinceCode = activityProvinceCode
          this.followRequest.appointment.driveCityCode = activityCityCode
          this.followRequest.appointment.driveDistrictCode = activityAreaCode
          // 判断活动开始时间是否晚于当前时间，是则默认选择活动第一天  不是则默认选中今天
          if (!this.followRequest.appointment.appointmentStartTime&&!this.followRequest.appointment.appointmentEndTime&&!dayjs(activityStartTime).isBefore(dayjs(),'minute')){
            this.followRequest.appointment.appointmentStartTime = moment(activityStartTime).format('YYYY-MM-DD')+' 00:00:00'
            this.followRequest.appointment.appointmentEndTime = moment(activityStartTime).format('YYYY-MM-DD')+' 00:30:00'
          }
        }
      }
    },
    'selectActivity.activityStartTime':{
      handler(val){
        if (val && typeof val ==='string'){
          this.getMinDate = dayjs(val).toDate()
          return
        }
        this.getMinDate = dayjs().toDate()
      },
      immediate: true
    },
    'selectActivity.activityEndTime':{
      handler(val){
        if (val && typeof val ==='string' ){
          this.getMaxDate = dayjs(val).toDate()
          return
        }
        this.getMaxDate = dayjs().add(12,'month').toDate()
      },
      immediate: true
    },
    showKnowEv: {
      handler(val) {
        if (val) {
          this.infos.forEach((item, index) => {
            this.$refs[`editInformation_${index}`][0].UPDATELISTITEMS(['knowEv'], true)
          })
        } else {
          this.infos.forEach((item, index) => {
            this.$refs[`editInformation_${index}`][0].UPDATELISTITEMS(['knowEv'], false)
          })
        }
      },
      immediate: true
    },
    showMoreInfo: {
      handler(val) {
        if (val) {
          if (this.showKnowEv) {
            this.infos.forEach((item, index) => {
              this.$refs[`editInformation_${index}`][0].UPDATELISTITEMS(['knowEv'], true)
            })
          } else {
            this.infos.forEach((item, index) => {
              this.$refs[`editInformation_${index}`][0].UPDATELISTITEMS(['knowEv'], false)
            })
          }
        }
      },
      immediate: true
    },
    forShowCarInfo: {
      handler(val) {
        if (val) {
          this.infos.forEach((item, index) => {
            // this.$refs[`editInformation_${index}`][0].UPDATELISTITEMS(['knowEv'], true)
          })
        } else {
          this.infos.forEach((item, index) => {
            // this.$refs[`editInformation_${index}`][0].UPDATELISTITEMS(['knowEv'], false)
          })
        }
      },
      immediate: true
    }
  },
  mounted() {
    const { followResult, followMode } = this.$route.query
    if (followMode) this.followRequest.followMode = followMode
    if (followResult) this.followRequest.followResult = followResult
    this.init()
  },
  methods: {
    async init() {
      this.getAndSetTD() // 标签字典数据
      loading.showLoading()
      let res = await clueServices.getCluesTags({ id: this.$route.query.id })
      loading.hideLoading()
      const infosGN = groupBy(res, 'groupCode')
      const infos = Object.entries(infosGN)
      this.infos = infos.map(arr => {
        let [gName = '', items = []] = arr
        const name = items[0].groupName

        items = items
          .map(i => {
            const { keyName: name, keyCode } = i
            // 如果是兴趣爱好，额外处理
            if (keyCode === 'hobby') {
              // 如果是字符串，则用英文逗号分隔
              if (isString(i.value)) {
                i.value = i.value.split(',')
              }
            }
            return {
              ...i,
              name
            }
          })

        return {
          gName,
          name,
          items
        }
      })
    },
    getQueryLabels(key) {
      return this.dictionaries.filter(({ keyCode }) => { return keyCode === key }).map((item) => {
        return {
          name: item.valueName,
          code: item.valueCode,
          ...item
        }
      })
    },
    async getAndSetTD() {
      loading.showLoading()
      const res = await baseDataServices.getTagsDicsByKey().finally(() => loading.hideLoading())
      loading.hideLoading()
      this.dictionaries = res
    },
    onClickShortcuts({ name }) {
      this.followRequest.followRemark += name
      this.shortcutsSelected = true
    },
    // 页面元素切换时 清空对应元素数据
    changeShow(code, val, oldVal) {
      // 切换了跟进方式 不需要改变跟进结果的集中情况
      const noNeedChange = [
        !oldVal && ['1010001', '1010002'].includes(val) , // 首次选择跟进方式 且不是店内接待
        val === '1010001' && oldVal === '1010002', // 电话 和企微来回切换
        val === '1010002' && oldVal === '1010001', // 电话 和企微来回切换
      ]
      switch (code){
        case 'followMode':// 跟进方式变更
          if (!noNeedChange.some(item => { return item })) {
            this.followRequest.followResult = this.isShopReceiveUpdate ? this.followRequest.followResult : ''
          }
          this.followRequest.personCount = 1
          this.followRequest.isActivity = ''
          if (this.followRequest.followMode === '1010003'){
            // 进店时间默认为当前时间
            const nDate = dayjs()
            this.followRequest.storeOfTime = dateFormat(padTime(nDate), 'HH:mm')
          } else {
            this.followRequest.storeOfTime = ''
          }
          break
        case 'followResult': // 跟进结果变更
          if (!this.appointmentIdSelectId) this.followRequest.appointment.appointmentTypeCode = ''
          break
        case 'appointmentTypeCode': // 预约事项变更
          if (!this.appointmentIdSelectId) this.followRequest.appointment.typeCode = ''
          break
        case 'typeCode': // 试驾场景变更
          if (!this.appointmentIdSelectId) {
            // this.addressObj = {}
            // this.followRequest.driveAddress = ''
          }
          break
      }
    },
    // 店内接待没有取消预约
    changeFollowMode() {
      if (this.isShopReceiveUpdate) return false
      if (this.getShopReceive){
        this.followRequest.followResult = ''
      }
    },
    // 校验品牌车型车型是否完整
    carInfoValidator(value) {
      const { seriesName, modelName } = this.followRequest.carInfo
      if ((!seriesName || !modelName) && value) {
        this.carInfoErrMsg = '请完善车系车型'
        return false
      }
      this.carInfoErrMsg = ''
      return true
    },
    // 清空已选择数据
    clearInfo() {
      this.followRequest.failCode = ''
      this.followRequest.sysRemark = ''
      if (!this.appointmentIdSelectId) {
        this.followRequest.appointment = this.$options.data().followRequest.appointment
      }
    },
    // 获取跟进截止时间
    async getPlaneTime() {
      // 已签单不显示下次跟进时间
      if (this.orderStatus.includes(this.followRequest.state)) return
      this.followRequest.planTime = ''
      const { leadLevel, planDriveTime='', tryDriveLevel, appointment: { appointmentStartTime='', }, followResult } = this.followRequest
      const params = {
        leadLevel,
        tryDriveLevel,
        followResult,
        id: this.clueDetail.id || '',
      }
      const data = await clueServices.getMaxPlaneTime(params)
      this.planeMaxDate = data ? new Date(dayjs().add(data, 'days').format('YYYY-MM-DD')) : this.planeMaxDate
      this.followRequest.planTime = dateFormat(this.planeMaxDate,'YYYY-MM-DD 23:59:59')
      this.defaultValue = this.planeMaxDate
    },
    // 回填预约信息
    async backFill() {
      const { appointmentEndTime, appointmentStartTime, seriesCode, seriesName, modelCode, modelName,vehicleCode,vehicleName } = this.appointmentSelectItem
      for (var key in this.followRequest.appointment) {
        this.followRequest.appointment[key] = this.appointmentSelectItem[key]
      }
      // 兼容
      this.followRequest.modelCode = this.followRequest.carInfo.modelCode || modelCode
      this.followRequest.modelName = this.followRequest.carInfo.modelName || modelName
      this.followRequest.seriesName = this.followRequest.carInfo.seriesName || seriesName
      this.followRequest.seriesCode = this.followRequest.carInfo.seriesCode || seriesCode
      this.followRequest.appointment.appointmentTimeShow = `${dateFormat(appointmentStartTime, 'YYYY-MM-DD')} ${dateFormat(appointmentStartTime, 'HH:mm')}-${dateFormat(appointmentEndTime, 'HH:mm')}`
      // 回显上门试驾地址
      if (this.followRequest.appointment.typeCode==='2002002'){
        const { driveProvinceCode,driveProvinceName,driveCityCode,driveCityName,driveDistrictCode,driveDistrictName } = this.followRequest.appointment
        this.addressObj = { driveProvinceCode,driveProvinceName,driveCityCode,driveCityName,driveDistrictCode,driveDistrictName }
        this.$set(this,'driveCarInfo',{ modelCode:vehicleCode,modelName:vehicleName,seriesCode, seriesName })
        this.followRequest.modelCode = vehicleCode
        this.followRequest.modelName = vehicleName
      }
      // 集中试驾
      if (this.followRequest.appointment.typeCode==='2002003'){
        const res = await CarManageServices.getActivityById({ ids:[this.followRequest.appointment.testDriveActivityId] })
        this.$set(this,'selectActivity',res[0])
      } else {
        this.$set(this,'selectActivity',{})
      }
    },
    // 改变跟进结果
    onChangeResult({ dictCode, dictName }) {
      if (this.isShopReceiveUpdate) return
      this.followRequest.failCode = ''
      this.followRequest.sysRemark = ''
      // this.clearInfo()
      this.followRequest.followResult = dictCode
    },
    // 改变预约事项
    onChangeAppoinment({ dictCode, dictName }) {
      if (this.appointmentDisabled) return false
      if (this.followRequest.appointment.appointmentTypeCode == dictCode) return false
      // 预约到店可以改为试驾 预约试驾不能改为预约到店
      if (this.appointmentIdSelectId && this.appointmentSelectItem.appointmentTypeCode === '2001001') {
        this.$toast('预约试驾不允许修改！')
        return false
      }
      this.followRequest.appointment.appointmentTypeCode = dictCode
      this.followRequest.appointment.carNumber = ''
      this.followRequest.appointment.vehicleName = ''
    },
    // 改变试驾场景
    onChangeDrive({ dictCode, dictName }) {
      if (this.appointmentDisabled) return false
      // this.followRequest.appointment.appointmentStartTime = ''
      this.followRequest.appointment.vehicleName = ''
      this.followRequest.modelCode = ''
      this.followRequest.modelName = ''
      this.followRequest.seriesName = ''
      this.followRequest.seriesCode = ''
      // 到店试驾切换为上门试驾时，需要清除车牌号和vin
      if (this.followRequest.appointment.typeCode==='2002001'&&dictCode==='2002002'){
        this.followRequest.appointment.carNumber = ''
        this.followRequest.appointment.vinCode = ''
      }
      if (dictCode==='2002003'){
        // 清除预约时间，避免已选择的时间不在活动时间内
        this.followRequest.appointment.appointmentTimeShow = ''
        this.followRequest.appointment.appointmentStartTime = ''
        this.followRequest.appointment.appointmentEndTime = ''
        this.appointmentTime = []
      }
      this.followRequest.appointment.typeCode = dictCode
    },
    // 集中试驾活动
    async getActivity(){
      const params = { statusFlag:2 }
      const res = await CarManageServices.activityPagePost(params)
      this.activityList = (res||[]).map(item=>({
        name:item.activityTitle,
        ...item
      }))
      if (this.activityList.length === 1){
        this.$set(this,'selectActivity',this.activityList[0])
      }
    },
    // 切换选择预约试驾单
    changeAppointment(item) {
      this.followRequest.followResult = ''
      this.followRequest.appointment = this.$options.data().followRequest.appointment
      this.appointmentIdSelectId = item.id
    },
    // 确认预约数据
    confirmScheduling(data) {
      data.appointmentStartTime = dateFormat(data.appointmentStartTime, 'YYYY-MM-DD HH:mm:ss')
      data.appointmentEndTime = dateFormat(data.appointmentEndTime, 'YYYY-MM-DD HH:mm:ss')
      const { appointmentStartTime, appointmentEndTime, vehicleCode, vehicleName, seriesCode, seriesName } = data
      for (var key in this.followRequest.appointment) {
        this.followRequest.appointment[key] = data[key]
      }
      this.followRequest.appointment.appointmentTimeShow = `${dateFormat(appointmentStartTime, 'YYYY-MM-DD')}  ${dateFormat(appointmentStartTime, 'HH:mm')}-${dateFormat(appointmentEndTime, 'HH:mm')}`
      this.followRequest.modelCode = vehicleCode
      this.followRequest.modelName = vehicleName
      this.followRequest.seriesCode = seriesCode
      this.followRequest.seriesName = seriesName
      this.followRequest.carInfo = {
        seriesCode,
        seriesName,
        vehicleCode,
        vehicleName
      }
      this.showScheduling = false
    },
    // 选择战败原因
    onSelectDefeatReasons(field, name) {
      selectDefeatReasons({ bizType: 1 }).then((data) => {
        this.followRequest[field] = ''
        this.followRequest[name] = ''
        const reasonObj = data[data.length-1] || {}
        const { id, reasonType, requireFlag } = reasonObj
        this.followRequest[field] = id
        this.followRequest[name] = data.map((k) => k.reason).join('/')
        if (reasonType === 1) {
          this.supplement = false
          this.supplementRequired = false
        } else {
          this.supplement = true
          this.supplementRequired = requireFlag === 1
        }
      })
    },
    async openPicker(){
      // 加载客户顾虑列表
      const concernList = await baseDataServices.clueConcernsTree({ topLevel: 1 })
      this.concernList = concernList.map(i => {
        const { name, id } = i
        const children = i.children.map(i => {
          const { name, id } = i
          return {
            ...i,
            text: name,
            value: id
          }
        })
        return {
          ...i,
          text: name,
          value: id,
          children
        }
      })
      this.multipleCascadering = true
    },
    getCMValue(valueCode) {
      if (!valueCode || !isArray(valueCode)) return ''

      let strs = valueCode.map(item => {
        const { name, nameEn, children } = item
        let str = this.getLangIsZH ? name : nameEn

        let modelsStr = []
        if (children && isArray(children) && children.length && children.length > 0) {
          str += this.$t('：')
          children.forEach(j => {
            const { name, nameEn } = j
            modelsStr.push(this.getLangIsZH ? name : nameEn)
          })

          if (modelsStr && modelsStr.length) {
            str += ' ' + modelsStr.join('，')
          }
        }
        return str
      })
      return strs.join('；')
    },
    handleCompetingOK(values) {
      this.$set(this.followRequest,'concern',values)

      this.multipleCascadering = false
    },
    async selectField(field, name, dictType, multiple = false) {
      if (dictType === 'defeatReason') {
        this.onSelectDefeatReasons(field, name)
        return false
      }
      let reasonList = []
      if (['invalidReason', 'defeatReason'].includes(dictType)) {
        const requestUrl = dictType === 'invalidReason' ? 'getInvalidReasonList' : 'getDefeatReasonList'
        const result = await baseDataServices[requestUrl]({ pageNum: 1, pageSize: 9999 })
        reasonList = result || []
      }
      // 无效战败原因
      const _obj = {
        field,
        dictType,
        multiple,
        reasonList,
        lastSelect: this.followRequest[field]
      }
      commonSelector(_obj).then(res => {
        this.followRequest[field] = res.dictCode
        this.followRequest[name] = res.dictName
      })
    },
    // 选择时间
    selectDate(field,type='date', title) {
      const { minDate, maxDate } = this
      selectDate({ type, minDate, maxDate })
        .then((res) => {
          if (type === 'date') {
            this.followRequest[field] = dateFormat(res,'YYYY-MM-DD')
          } else {
            this.followRequest[field] = res
          }
        })
        .catch(() => {})
    },
    selecNextFollowTime() {
      this.showNextFollowTime = true
    },
    dateConfirm(date) {
      this.followRequest.planTime = dateFormat(date,'YYYY-MM-DD') + ' 23:59:59'
      this.showNextFollowTime = false
    },
    // 选择试驾时间区间 type:1 预约到店  2 预约试驾  
    selectCalendar(type) {
      if (this.appointmentDisabled) return false
      // 预约时间
      const { appointmentStartTime,appointmentEndTime } = this.followRequest.appointment
      // 当前时间
      const nowDay = dayjs()
      const nowDdate = dateFormat(nowDay,'YYYY-MM-DD')

      // 试驾开始时间
      let testDriveStart = dayjs(getNearHour(new Date()))

      // 预约到店是 10 分钟为刻度  预约试驾是半个小时为刻度
      let nowStartTime,nowEndTime
      if (type===1){
        nowStartTime =dateFormat(padTime(nowDay.add(10, 'minute')),'HH:mm') 
        nowEndTime = dateFormat(padTime(nowDay.add(40, 'minute')),'HH:mm') 
      } else {
        // 试驾时间调整为 09:00-24:00
        if (testDriveStart.isBefore(dayjs(`${nowDdate} 09:00:00`))){

          testDriveStart = testDriveStart.hour(9).minute(0)
          nowStartTime = testDriveStart.format('HH:mm')
          nowEndTime = testDriveStart.add(30,'minute').format('HH:mm')
        
        } else if (testDriveStart.isAfter(dayjs(`${nowDdate} 23:30:00`),'minute')){
          
          testDriveStart = testDriveStart.hour(23).minute(0)
          nowStartTime = testDriveStart.add(30,'minute').format('HH:mm')
          nowEndTime = nowStartTime

        } else {
          nowStartTime = testDriveStart.format('HH:mm')
          nowEndTime = testDriveStart.add(30,'minute').format('HH:mm')
        }
      }

      let start='',end=''
      if (appointmentStartTime && appointmentEndTime){
        // 预约时间是否已过去，过去了则用当前时间
        if (dayjs(appointmentStartTime).isBefore(dayjs(nowDdate+' '+ nowStartTime),'minute')){
          start= dateFormat(nowDdate +' '+ nowStartTime +':00','YYYY/MM/DD HH:mm:ss'),
          end= dateFormat(nowDdate +' '+ nowEndTime +':59','YYYY/MM/DD HH:mm:ss')
        } else {
          // 否则回显预约时间
          start= dateFormat(appointmentStartTime,'YYYY/MM/DD HH:mm:ss'),
          end= dateFormat(appointmentEndTime,'YYYY/MM/DD HH:mm:ss')
        }
      } else {
        // 没有预约时间，则用当前时间
        start= dateFormat(nowDdate +' '+ nowStartTime +':00','YYYY/MM/DD HH:mm:ss'),
        end= dateFormat(nowDdate +' '+ nowEndTime +':59','YYYY/MM/DD HH:mm:ss')
      }
      // 临时预约试驾时间集合
      this.appointmentTime = [start,end]
      this.showCalendar = true
    },
    
    handleDateTimeConfirm2(){
      const [start,end] = this.appointmentTime
      if (!start && !end){
        this.$toast('请选择时间')
        return
      }
      if (dayjs(end).isBefore(dayjs(start),'minute') || dayjs(end).isSame(dayjs(start),'minute')){
        this.$toast('请确认，结束时间要大于开始时间')
        return
      }
      this.followRequest.appointment.appointmentTimeShow = `${dateFormat(start,'YYYY-MM-DD')}  ${dateFormat(start,'HH:mm')}-${dateFormat(end,'HH:mm')}`
      this.followRequest.appointment.appointmentStartTime = dateFormat(start,'YYYY-MM-DD HH:mm:ss')
      this.followRequest.appointment.appointmentEndTime = dateFormat(end,'YYYY-MM-DD HH:mm:ss')
      this.showCalendar = false
    },
    // 选择顾问
    onChangeSale() {
      if (this.appointmentDisabled || this.assignUserDefeault) return false
      selectSalesperson({ withPermission: true }).then((data) => {
        this.followRequest.assignUserId = data.value
        this.followRequest.assignUserName = data.dictName
      })
    },
    // 选择意向车型 type:carInfo为意向信息  driveCarInfo为上门试驾车型
    selectCarModel(type) {
      if (type==='driveCarInfo'&&this.appointmentDisabled) return
      let initCarInfo = {}
      if (type==='carInfo'){
        initCarInfo = this.followRequest.carInfo
      } else {
        initCarInfo = this.driveCarInfo
      }
      // const { carInfo: initCarInfo } = this.followRequest
      selectCar({ initCarInfo })
        .then(carInfo => {
          if (typeof carInfo.modelCode === 'undefined') {
            this.$toast('请选择正确的车系车型')
            return
          }
          if (type==='carInfo') {
            this.followRequest.carInfo = carInfo
            this.followRequest.modelCode = carInfo.modelCode
            this.followRequest.modelName = carInfo.modelName
            this.followRequest.seriesName = carInfo.seriesName
            this.followRequest.seriesCode = carInfo.seriesCode
          }
          else {
            this.driveCarInfo = carInfo
            this.followRequest.modelCode = carInfo.modelCode
            this.followRequest.modelName = carInfo.modelName
            this.followRequest.seriesName = carInfo.seriesName
            this.followRequest.seriesCode = carInfo.seriesCode
          }
          this.$refs.carInfo.validate()
        })
        .catch(() => { })
    },
    // 选择排程信息
    chooseScheduling() {
      if (this.appointmentDisabled) return false
      this.showScheduling = true
    },
    // 校验
    async validateForm() {
      this.$refs.addressSystem&&this.$refs.addressSystem.$refs.form.validate()
      return this.$refs.clueFollowComponent.validate()
    },
    exportData() {
      let leadLevelInfo = {}
      // 有试驾评级的数据
      if (['1011002', '1011004', '1011005', '1011006','1011035'].includes(this.followRequest.followResult) && this.followRequest.visitResult !== '1019001') {
        leadLevelInfo = this.$refs.leadLevel.exportLeadLevelData()
      }
      const { planTime } = this.followRequest
      const followRequestParams = JSON.parse(JSON.stringify(this.followRequest))
      if (this.appointmentIdSelectId) {
        followRequestParams.appointment.id = this.appointmentIdSelectId
      }
      if (this.supplement && followRequestParams.sysRemarkSupplement) {
        followRequestParams.sysRemark = followRequestParams.sysRemark + '/' + followRequestParams.sysRemarkSupplement
      }
      const { planBuyTime } = leadLevelInfo
      const params = {
        ...followRequestParams,
        ...leadLevelInfo,
        planBuyTime: planBuyTime,
        planTime: dateFormat(planTime,'YYYY-MM-DD 23:59:59'),
        leadLevel: leadLevelInfo.leadLevel ? leadLevelInfo.leadLevel : this.clueDetail.leadLevel
      }
      if (!params.carInfo?.modelCode) params.carInfo.modelCode = this.followRequest.appointment.vehicleCode
      if (!params.carInfo?.modelName) params.carInfo.modelName = this.followRequest.appointment.vehicleName
      return params
    },
    // 店内接待
    // shopReceive(unEqual) {
    //   const { followMode } = this.followRequest
    //   return followMode && (unEqual? followMode !== '1010003' : followMode === '1010003')
    // },
    // 上门试驾地址切换
    onAddressChange(val){
      this.$set(this,'addressObj',val)
      Object.assign(this.followRequest.appointment,val)
    },
    // 选择集中试驾活动
    onActivitySelect(activityItem){
      this.$set(this,'selectActivity',activityItem)
      this.isShowActivityList = false
      // 清除选中的车系和预约时间
      this.followRequest.seriesName = ''
      this.followRequest.seriesCode = ''
      this.followRequest.seriesNameEn = ''
      this.followRequest.appointment.appointmentEndTime = ''
      this.followRequest.appointment.appointmentStartTime = ''
      this.followRequest.appointment.appointmentTimeShow = ''
      
    },
    // 集中试驾活动可选的车系
    async getSeriesByActivity(activityId){
      const params = {
        id: activityId,
        flag: 1, // 1：车系、2：车型、3：车辆
      }
      const res = await CarManageServices.vehicleInfoPost(params)
      this.activitySeriesList = res.map(item=>({
        name: item.seriesName,
        ...item
      }))
      // 如果只有一个车系，默认选中
      if (this.activitySeriesList.length===1){
        this.followRequest.seriesName = this.activitySeriesList[0].seriesName
        this.followRequest.seriesNameEn = this.activitySeriesList[0].seriesNameEn
        this.followRequest.seriesCode = this.activitySeriesList[0].seriesCode
      }
    },
    // 选择集中试驾车系
    onActivitySeriesSelect(activitySeries){
      this.followRequest.seriesName = activitySeries.seriesName
      this.followRequest.seriesNameEn = activitySeries.seriesNameEn
      this.followRequest.seriesCode = activitySeries.seriesCode
    },
    onClickActivityTitle(){
      // 取消&修改预约时，不能改变活动
      if (this.appointmentDisabled) return 
      if (this.activityList.length===0){
        this.$toast('暂无可选活动')
        return
      }
      this.isShowActivityList = true
    },
    onClickActivitySeries(){
      if (this.appointmentDisabled) return 
      this.isShowActivitySeries = true
    },
    filter(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 10 === 0)
      }
      return options
    },
    // 完善更多信息
    async improveUser() {
      if (this.infos.length) this.showMoreInfo = true
    },
    // 收起更多信息
    collapse() {
      this.showMoreInfo = false
    },
    // 根据 跟进结果 改变标签显示项
    changeTags(val) {
      // 非 战败/无效 
      if (!val) return false
      if (!['1011001', '1011003', '1011007'].includes(val)) {
        this.infos.forEach((item, index) => {
          this.$refs[`editInformation_${index}`][0].UPDATELISTITEMS(['intendModel', 'competitive', 'competitiveFocus', 'planBuyTime', 'payType', 'coMakers', 'partnerTry'], true)
        })
      } else {
        this.infos.forEach((item, index) => {
          this.$refs[`editInformation_${index}`][0].UPDATELISTITEMS(['intendModel', 'competitive', 'competitiveFocus', 'planBuyTime', 'payType', 'coMakers', 'partnerTry'], false)
        })
      }
      if (this.showGender) {
        this.infos.forEach((item, index) => {
          this.$refs[`editInformation_${index}`][0].UPDATELISTITEMS(['userSex'], true)
        })
      } else {
        this.infos.forEach((item, index) => {
          this.$refs[`editInformation_${index}`][0].UPDATELISTITEMS(['userSex'], false)
        })
      }
    },
    // 打开第三方的页面
    openRoom() {
      this.roomDialogShow = true
    },
    closeRoomDialog(success) {
      this.roomDialogShow = false
      if (success === 'success') {
        this.roomSuccess = this.$t('认领成功')
      }
    },
    followRemarkValidator(value) {
      if (['1011034','1011035'].includes(this.followRequest.followResult) || this.followRemarkNotTenLength) {
        this.followRemarkErrMsg = ''
        return true
      }
      if (value.length < 10) {
        this.followRemarkErrMsg = this.$t('请输入不少于10个字的跟进内容')
        return false
      }
      this.followRemarkErrMsg = ''
      return true
    },
    isPositiveInteger(val) {
      if (!this.testDriveMark && !val) return ''
      // 正则表达式验证是否为正整数
      return /^[1-9]\d*$/.test(val)
    }
  }
}
</script>
<style lang="less" scoped>
.moreInfo{
  .form-collapse-item{
    margin-top: 16px;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
    border-radius: 8px !important;
    background-color: #fff;
    overflow: hidden;
  }
}
.popupNav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  border-bottom: #f5f5f5 solid 1px;
  color: #0D171A;
  background: #EED484;
  box-sizing: border-box;
  padding:0 16px;
}
/deep/ .complete {
  display: flex;
  align-items: center;
  justify-content: center;
  color: @yellow-text;
  cursor: pointer;
}
.followInfo{
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  /deep/ .custom-radio-group-wrap {
    margin-top: -10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    .custom-radio-wrap {
      margin-top: 12px;
      margin-left: 12px;
      .van-radio__icon{
        height: 24px;
      }
      .custom-radio {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        height: 24px;
        color: rgba(13,23,26,0.45);
        background-color: #F5F5F5;
        border-radius: 3px;
        font-size: 12px;
        // &.checked {
        //   // border: 1px solid @yellow;
        //   // color: @yellow-text
        // }
        &.wechatCheck{
          color: #0D171A;
          background: #EED484;
        }
        &.appointmentTypeCheck{
          color: #0D171A;
          background: #EED484;
        }
        &.typeCheck{
          color: #0D171A;
          background: #EED484;
        }
        &.commonCheck{
          color: #0D171A;
          background: #EED484;
        }
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background-color: #ebedf0;
          border-color: #c8c9cc
        }
      }
    }
  }
  .leadLevelInfo{
    padding: 16px 0;
    overflow: hidden;
    background: #f5f5f5;
  }
  /deep/ .schedulingBox{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    .schedulingVehicle{
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      .scheItem{
        &.carNumber{
          color: #fff;
          background: #3476FE;
          padding: 0 4px;
          margin: 8px 0;
          border-radius: 2px;
        }
      }
    }
    .chooseBtn{
      // width: 40px;
      text-align: right;
      color: #3476FE;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .appointmentBox{
    /deep/ .van-tabs--line{
     .van-tabs__nav--complete{
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
  /deep/ .van-cell{
    &.errorField{
      &.van-field--error{
        .van-field__body{
          .schedulingBox::before{
            content: '请选择排程信息';
            color: #ee0a24;
            font-size: 12px;
          }
        }
      }
    }
    &.appointment_disabled{
      .van-cell__title{
        color: #c8c9cc;
      }
      .custom-radio{
        color: rgba(13,23,26,0.45);
        background-color: #F5F5F5;
        &.checked{
          background: #CECECE;
          color: rgba(13,23,26,0.45);
        }
      }
      .schedulingBox{
        color: #c8c9cc;
        .carNumber{
          background: #CECECE;
          color: rgba(13,23,26,0.45);
        }
      }
      .van-field__body{
        .van-field__control{
          color: #c8c9cc;
        }
      }
    }
  }
  /deep/ .van-radio__icon{
    // height: 24px;
  }
}
</style>

