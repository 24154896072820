<template>
  <van-form ref="leadLevelComponent" class="leadLevelComponent" input-align="right" error-message-align="right">
    <!-- 预计购车时间 -->
    <van-field
      :value="leadLevelInfo.planBuyTime | codeFormat"
      :label="$t('预计购车时间')"
      :placeholder="$t('请选择')"
      right-icon="arrow"
      readonly
      input-align="right"
      :required="required"
      :rules="[{required:required}]"
      @click="selectField('planBuyTime', 1009, false)">
    </van-field>
    <!-- 付款方式 -->
    <van-field
      name="radio"
      :label="$t('付款方式')"
      input-align="right"
      error-message-align="right"
      :required="required"
      :rules="[{required:required, message: $t('请选择')}]">
      <template #input>
        <van-radio-group
          v-model="leadLevelInfo.payType"
          icon-size="32px"
          class="custom-radio-group-wrap">
          <van-radio v-for="item in payTypeList" :key="item.dictCode" :name="item.dictCode" class="custom-radio-wrap">
            <template #icon="props">
              <div class="custom-radio" :class="{ checked: props.checked, commonCheck: props.checked }">{{item.dictName}}</div>
            </template>
          </van-radio>
        </van-radio-group>
      </template>
    </van-field>
    <!-- 共同决策人 -->
    <van-field
      name="radio"
      :label="$t('共同决策人')"
      :value="leadLevelInfo.coMakers | codeFormat"
      :placeholder="$t('请选择')"
      input-align="right"
      error-message-align="right"
      right-icon="arrow"
      :required="required"
      :rules="[{required:required}]"
      @click="selectField('coMakers', 1030, false)">
    </van-field>
    <!-- 共同决策人是否试驾 -->
    <van-field
      v-if="leadLevelInfo.coMakers&&!['103005','103006'].includes(leadLevelInfo.coMakers)"
      :label="$t('共同决策人是否试驾')"
      :placeholder="$t('请选择')"
      :value="leadLevelInfo.partnerTry | codeFormat"
      right-icon="arrow"
      readonly
      input-align="right"
      :required="required"
      :rules="[{required:required}]"
      @click="selectField('partnerTry', 1002, false)">
    </van-field>
    <!-- 竞品品牌车型 -->
    <van-field
      name="radio"
      :label="$t('竞品品牌车型')"
      :placeholder="$t('请选择')"
      :value="getCMValue(leadLevelInfo.competitive)"
      input-align="right"
      error-message-align="right"
      right-icon="arrow"
      :required="required"
      :rules="[{required:required}]"
      @click="openPicker">
    </van-field>
    <!-- 竞品关注点 -->
    <van-field
      name="radio"
      :label="$t('竞品关注点')"
      :placeholder="$t('请选择')"
      :value="leadLevelInfo.competitiveFocus | focusText($store,1031)"
      input-align="right"
      right-icon="arrow"
      error-message-align="right"
      :required="required"
      :rules="[{required:required}]"
      @click="selectField('competitiveFocus', 1031, true)">
    </van-field>
    <!-- 本人是否试驾选配 -->
    <van-field
      :label="$t('本人是否试驾选配')"
      :placeholder="$t('请选择')"
      :value="leadLevelInfo.isDriveOption ? testDriveInfo[leadLevelInfo.isDriveOption] : drivingTime ? testDriveInfo['1006002'] : testDriveInfo['1006004']"
      readonly
      :required="required"
      :rules="[{required:required}]"
      input-align="right">
      <template #input>
        <span>{{ leadLevelInfo.isDriveOption ? testDriveInfo[leadLevelInfo.isDriveOption] : drivingTime ? testDriveInfo['1006002'] : testDriveInfo['1006004'] }}</span>
      </template>
    </van-field>

    <!-- 选配信息 当“本人是否试驾选配” 为 中选配结果为 已选配， 则需要展示选配信息 -->
    <div v-if="['1006001', '1006003'].includes(leadLevelInfo.isDriveOption) && leadLevelInfo.carOrderMaterial" style="background-color: #fff;color: #0d171a;padding: 10px 16px;box-sizing: border-box;">
      <van-row style="border-bottom: 1px solid #ebedf0;">
        <van-col span="8">{{$t('选配信息')}}</van-col>
        <van-col span="16" style="text-align: right;">
          <div>车系车型：{{ leadLevelInfo.carOrderMaterial?.seriesName }} {{ leadLevelInfo.carOrderMaterial?.modelName }}</div>
          <div>外饰颜色：{{ leadLevelInfo.carOrderMaterial?.colourName }}</div>
          <div>内饰颜色：{{ leadLevelInfo.carOrderMaterial?.interiorName }}</div> 
          <!-- <div v-if="leadLevelInfo.carOrderMaterial?.[0].carOrderMaterial?.carOptionsList">选装：
            <div v-for="(item, index) in leadLevelInfo.carOrderMaterial?.[0].carOrderMaterial?.carOptionsList" :key="index">{{index + 1}}.{{ item.optionsName }}</div>
          </div> -->
        </van-col>
      </van-row>
    </div>
    <!-- 多级选择框 -->
    <!-- <form-select-wrap-cascader
      :columns="cmList"
      :showPicker="showCMPicker"
      :title="$t('请选择')"
      :loading="cmLoading"
      @confirm="onCMConfirm"
      @cancel="showCMPicker = false"
    >
    </form-select-wrap-cascader> -->

    <!-- 竞品 -->
    <van-popup v-model="competing" style="width:100%; height:100%">
      <SelectCompeting v-if="competing" :list="cmList" :title="listSelectCompetTitle" :details="leadLevelInfo.competitive" @close="competing = false" @ok="handleCompetingOK"></SelectCompeting>
    </van-popup>
  </van-form>
</template>
<script>
import clueServices from '@/services/clueServices'
import { mapGetters } from 'vuex'
import commonSelector from '@/components/common-selector'
import { validate } from '@/utils/validate'
import formSelectWrapCascader from '@/components/select-car/form-select-wrap-cascader.vue'
import { last, isArray } from 'lodash'
import baseDataServices from '@/services/baseDataServices'
import { i18n } from '@/main'

// Components
import SelectCompeting from '@/modules/clue/component/tags/SelectCompeting.vue'

export default {
  name: 'leadLevel',
  components:{ formSelectWrapCascader, SelectCompeting },
  filters:{
    focusText(val,store,dictType){
      if (!val) return ''

      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (targetCode) => {
        const dictList = dictHash[dictType] || []
        const target = dictList.find(item=>{ return item.code===targetCode })
        return target
      }
      const list = val.split(';')
      const text = list.map(item=>getOptionsByDictType(item)['name'])
      return text.join(',')
    }
  },
  props: {
    leadLevelInfoFill: {
      type: Object,
      default: () => ({})
    },
    required: {
      type: Boolean,
      default: false
    },
    drivingTime: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      validate,
      minDate: new Date(),
      maxDate: new Date(2099, 0, 31),
      leadLevelInfo: {
        planBuyTime: '', // 预计购买时间
        payType: '', // 付款方式
        coMakers: '', // 共同决策人
        partnerTry: '', // 共同决策人是否试驾
        competitive: [], // 竞品对象
        competitiveFocus: '',// 竞品关注点
        isDriveOption: '', // 本人是否试驾选配
        leadLevel: '',
      },
      showPopover: false,
      showPopoverTwo: false,
      cmList: [],
      cmLoading: false,
      showCMPicker: false,
      competing: false,
      listSelectCompetTitle: '',
      i18n
    }
  },
  computed: {
    ...mapGetters(['dictHash', 'templateClueInfo']),
    payTypeList() {
      const arr = this.dictHash[1001] || []
      return arr.map((item) => ({ ...item, dictName: item.name, dictCode: item.code }))
    },
    testDriveInfo(){
      const arr = this.dictHash[1006] || []
      const map = {}
      arr.forEach(item => {
        map[item.code] = item.name
      })
      return map
    },
    getLangIsZH() {
      const lang = i18n.locale
      return lang.includes('zh')
    }
  },
  watch: {
    leadLevelInfo: {
      handler(){
        this.getLevel()
      },
      deep:true
    },
    'leadLevelInfo.coMakers':{
      handler(val){
        if (['103005','103006'].includes(val)){
          this.$set(this.leadLevelInfo,'partnerTry','')
        }
      }
    }
  },
  mounted() {
    // const { planBuyTime, payType, partnerTry } = this.templateClueInfo
    // Object.assign(this.leadLevelInfo, { planBuyTime, payType, partnerTry })
    this.leadLevelInfo = Object.assign(this.leadLevelInfo, this.leadLevelInfoFill)
  },
  methods: {
    selectField(field, dictType, multiple = false) {
      const _obj = {
        field,
        dictType,
        multiple,
        excludeCode: field === 'isDriveOption' ? ['1006001', '1006002', '1006003'] : [],
        isDisableTips: '不支持修改',
        lastSelect: this.leadLevelInfo[field]
      }
      commonSelector(_obj).then(res => {
        if (multiple){
          this.leadLevelInfo[field] = res.map(item=>item.code).join(';')
        } else {
          this.leadLevelInfo[field] = res.dictCode
        }
      })
    },
    // 获取意向评级
    async getLevel() {
      // const { planBuyTime, payType, coMakers, partnerTry, isDriveOption } = this.leadLevelInfo
      // const params = {
      //   planBuyTime, 
      //   payType,
      //   coMakers,
      //   partnerTry,
      //   isDriveOption
      // }
      const result = await clueServices.getClueLevel(this.leadLevelInfo)
      this.leadLevelInfo.leadLevel = result
      this.$emit('refreshLevel', this.leadLevelInfo.leadLevel)
    },
    // 校验
    async validateLeadInfo() {
      return this.$refs.leadLevelComponent.validate()
    },
    // 导出意向评级数据
    exportLeadLevelData() {
      const params = {
        ...this.leadLevelInfo,
      }
      return params
    },
    async openPicker(){
      // 加载竞品列表
      const cmList = await baseDataServices.getCompetitorModel()

      this.cmList = cmList.map(i => {
        const { brandName: text, brandNameEn, models = [], brandCode: value } = i
        const children = models.map(i => {
          const { modelName: text, modelCode: value, modelNameEn } = i
          return {
            ...i,
            text,
            name: text,
            nameEn: modelNameEn,
            value
          }
        })
        return {
          ...i,
          text,
          name: text,
          nameEn: brandNameEn,
          value,
          children
        }
      })
      this.listSelectCompetTitle = this.$t('竞品品牌车型')
      this.competing = true
    },
    getCMValue(valueCode) {
      console.info('getCMValue', valueCode)
      if (!valueCode || !isArray(valueCode)) return ''

      let strs = valueCode.map(item => {
        const { name, nameEn, code, models } = item
        let str = this.getLangIsZH ? name : nameEn
        str += this.$t('：')

        let modelsStr = []
        if (models && isArray(models) && models.length) {
          models.forEach(j => {
            const { name, nameEn, code } = j
            modelsStr.push(this.getLangIsZH ? name : nameEn)
          })

          if (modelsStr && modelsStr.length) {
            str += ' ' + modelsStr.join('，')
          }
        }

        return str
      })

      return strs.join('；')
    },
    handleCompetingOK(values) {
      this.$set(this.leadLevelInfo,'competitive',values)

      this.competing = false
    },
    onCMConfirm(value) {
      // 废弃
      if (value && value.length > 0) {
        if (value.length !== 2) {
          this.$toast('请完成全部选择')
          return false
        }
      }

      // 清理value
      const { brandCode, brandName, brandNameEn, modelCode, modelName, modelNameEn, text } = last(value)
      const obj = { brandCode, brandName, brandNameEn, modelCode, modelName, modelNameEn, text }
      this.$set(this.leadLevelInfo,'competitive',obj)

      this.showCMPicker = false
    },
  }
}
</script>
<style lang="less" scoped>
.leadLevelComponent{}
</style>
<style>
.Tips{
  background: rgba(0, 0, 0, 0.5);
  padding: 16px;
  font-size: 12px;
  color: #fff;
  border-radius: 8px;
  max-width: 200px;
}
</style>
