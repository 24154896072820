<!-- 线索跟进 -->
<template>
<div class="follow">
  <van-nav-bar
      v-if="!$isMobile && ['single_chat_tools', 'group_chat_tools'].includes($route.query.contextEntry)"
      fixed
      placeholder
      :title="$route.meta.title"
      left-text="返回"
      left-arrow
      z-index="1000"
      @click-left="$router.back()"
  />
  <van-collapse v-model="activeNames" style="padding: 16px;" :right-icon="false">
    <van-collapse-item class="form-collapse-item special-collapse-item" disabled name="1" style="background-color: transparent">
      <template #title>
        <div>{{$t('跟进信息')}}</div>
        <!-- <div class="icon" @click="improveUser">
          <van-icon name="edit" size="13px" color="#0040C5"/>
          {{$t('完善线索信息')}}
        </div> -->
      </template>
      <FollowInfo
        v-if="clueDetail.id"
        ref="followInfo"
        :clueDetail="clueDetail"
        :followResult="followResult"
        @changeTypeCode="changeTypeCode"
        @changeFollowResult="changeFollowResult">
      </FollowInfo>
    </van-collapse-item>
  </van-collapse>
  <div class="common-footer">
    <van-button class="btn back" @click="goBack">{{$t('返回')}}</van-button>
    <van-button class="btn submit" :loading="submitLoading" @click="onSubmit">{{ btnTxt }}</van-button>
  </div>
</div>
</template>
<script>
import FollowInfo from '../../components/FollowInfo'
import clueServices from '@/services/clueServices'
import { dateFormat } from '@/utils'
import loading from '@/utils/loading'
import { pick } from 'lodash'
export default {
  name: 'clueFollow',
  components: {
    FollowInfo,
  },
  data() {
    const btnTxt = this.$t('提交')
    return {
      activeNames: ['1'],
      clueDetail: {},
      id: '',
      appointmentId: '',
      followResult: '',
      fromPath: '',
      btnTxt,
      submitLoading: false
    }
  },
  beforeRouteEnter(to, from, next){
    next((vm) => {
      vm.fromPath = from.path
    })
  },
  created() {
    const { id, appointmentId, followResult } = this.$route.query
    this.id = id
    this.appointmentId = appointmentId
    this.followResult = followResult
    this.getClueDetail()
  },
  methods: {
    // 获取详情
    async getClueDetail() {
      this.clueDetail = await clueServices.getClueDetail({ id: this.id, appointmentId: this.appointmentId })
    },
    // 返回
    goBack() {
      this.$router.go(-1)
    },
    // 完善线索信息
    improveUser() {
      this.$router.push({
        name:'editClue',
        query:{
          id: this.id,
          operate: 'edit',
        }
      })
    },
    // 修改试驾场景
    changeTypeCode(val) {
      this.btnTxt = this.$t('提交')
      if (val == '2002002') {
        this.btnTxt = this.$t('提交审核')
      }
    },
    // 修改跟进结果
    changeFollowResult(val){
      if (val === '1011005'){
        this.btnTxt = this.$t('提交')
        return
      }
      if (val!=='1011005'&&this.$refs?.followInfo?.followRequest.appointment.typeCode==='2002002'){
        this.btnTxt = this.$t('提交审核')
      }
    },
    // 提交
    async onSubmit() {
      let validateList = []
      validateList.push(this.$refs.followInfo.validateForm())
      validateList.push(this.$refs.followInfo.$refs.leadLevel?.validateLeadInfo())
      const validation = Promise.all(validateList)
      // 输入内容校验
      const validationRes = await validation.catch(err => {
        this.$toast(this.$t('请完成必填字段的填写后再提交'))
      })
      if (!validationRes) return
      const followRequest = this.$refs.followInfo.exportData()
      let labelMap = {}
      this.$refs.followInfo.infos.forEach((item, index) => {
        labelMap = Object.assign({}, labelMap, this.$refs.followInfo.$refs[`editInformation_${index}`][0].EXPORTDATA())
      })
      const { id } = this.clueDetail
      const {
        seriesCode,
        seriesName,
        modelCode,
        modelName,
        storeOfTime
      } = followRequest
      const today = dateFormat(new Date(),'YYYY-MM-DD')
      const params = {
        id,
        seriesCode,
        seriesName,
        modelCode,
        modelName,
        ...followRequest,
        storeOfTime: storeOfTime?`${today} ${storeOfTime}:00` :''
      }
      params.labelMap = labelMap
      if (params.knowEv) {
        params.labelMap.knowEv = params.knowEv
      }
      if (params.drivePersonCount) {
        params.labelMap.drivePersonCount = params.drivePersonCount
      }
      if (params.partnerTry) params.labelMap.partnerTry = params.partnerTry
      const intendModel = {
        seriesCode,
        seriesName,
        modelCode,
        modelName,
      }
      params.labelMap.intendModel = intendModel
      delete params.carInfo
      // 战败和无效的时候 下次跟进时间不用传
      if (['1011003', '1011007'].includes(params.followResult)) {
        params.planTime = ''
      }
      let submitParams = {}
      if (params.visitResult === '1019001') {
        submitParams = pick(params, ['followMode', 'visitResult', 'followRemark', 'id'])
      } else {
        submitParams = params
      }
      if (this.submitLoading) return false
      this.submitLoading = true
      clueServices.followClue(submitParams).then(res => {
        loading.hideLoading()
        this.$toast.success(this.$t('跟进成功'))
        setTimeout(() => {
          if (this.fromPath == '/create-clue') {
            this.submitLoading = false
            this.$router.push({
              path: '/clue-list',
              query:{
                fromAssistant: true
              }
            })
          } else {
            this.submitLoading = false
            this.$router.go(-1)
          }
        }, 2000)
      }).catch(() => {
        this.submitLoading = false
        // loading.hideLoading()
      })
    },
  }
}
</script>
<style lang="less" scoped>
@import "../style/commonless.less";
.follow{
  padding-bottom: 96px;
  /deep/ .van-nav-bar--fixed {
    left: 50%;
    max-width: 500px;
    transform: translateX(-50%);
  }
  /deep/ .special-collapse-item{
    .van-collapse-item__title--disabled{
      .van-icon-arrow{
        display: none;
      }
    }
    .van-cell__title{
      display: flex;
      .icon{
        flex: 1;
        cursor: pointer;
        text-align: right;
        margin-left: 10px;
        font-size: 14px;
        color: #0040C5;
      }
    }
  }
}
</style>
