<template>
  <van-dialog
    ref="roomDialog"
    v-model="visible"
    :showConfirmButton="false"
    className="roomDialog"
    closeOnClickOverlay
    @closed="closed">
    <iframe :src="roomUrl"></iframe>
  </van-dialog>
</template>
<script>
import clueServices from '@/services/clueServices'
import loading from '@/utils/loading'
import { mapGetters } from 'vuex'
export default {
  props: {
  },
  data() {
    return {
      visible: true,
      url: ''
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    roomUrl() {
      if (!this.url) return ''
      const language = window.navigator.language.toLocaleLowerCase().includes('en') ? 'en' : 'zh'
      const { dealers=[] } = this.userInfo
      const url = `${this.url}?token=${sessionStorage.getItem('Token')}&clintType=2&language=${language}&dealerCode=${dealers[0].code}`
      console.log('roomUrl', url)
      return url
    }
  },
  mounted() {
    this.getUrl(),
    window.onmessage = this.handleMessage
  },
  methods: {
    // 注册 接收iframe的消息推送
    handleMessage(event) {
      const { data } = event
      // 认领成功
      if (data === 'register_done') {
        this.$emit('close', 'success')
      }
    },
    async getUrl() {
      loading.showLoading()
      const result = await clueServices.getShowRoomUrl()
      loading.hideLoading()
      this.url = result
    },
    closed() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="less">
  .roomDialog{
    width: calc(100% - 32px);
    height: 80%;
    margin-top: 10%;
    .van-dialog__content{
      height: 100%;
      iframe{
        width: 100%;
        height: 100%
      }
    }
  }
</style>