<script>
import formSelectWrap from '../common-selector/form-select-wrap'
import cellList from './cell'
import emptyLoading from '../empty-loading/empty-loading'

export default {
  components: {
    formSelectWrap,
    cellList,
    emptyLoading
  },
  props: {
    showPicker: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '请选择'
    },
    columns: {
      type: Array,
      default: function() {
        return []
      }
    },
    loading: {
      type: Boolean
    },
    empty: {
      type: Boolean
    },
  },
  data() {
    return {
      activeList: [], // 已选择的数据列表
      active: '', // 当前高亮的已选择的数据
      activeLevel: 0, // 当前活跃数据的所处
      cellActive: {} // 向cell传递点击的数据
    }
  },
  computed: {
    list: function() {
      if (this.activeLevel === 0) {
        return this.columns
      } else {
        if (this.active.children) return this.active.children
        const parentLevel = this.activeLevel - 1
        return this.activeList[parentLevel].children
      }
    }
  },
  methods: {
    onConfirm(){
      if (!this.activeList.length) {
        this.$toast('请选择战败原因')
        return
      }
      const children = this.activeList[this.activeList.length-1].children
      if (children && children.length) {
        this.$toast('请选择战败原因细分项')
        return
      }
      this.$emit('confirm', this.activeList)
    },
    onChange(item) {
      this.active = item
    },
    open() {
      this.$refs.wrap.open()
    },
    // 操作区点击
    optClick(item, index) {
      const optLength = this.activeList.length
      if (index === optLength && index !== 0) return
      this.cellActive = {}
      this.active = this.activeList[index - 1]
      const num = optLength - index
      this.activeList.splice(-num, num)
      this.activeLevel = index
    },
    // 响应cell组件
    onCellChange(data) {
      this.$emit('cellChange', data)
      const { level, active } = data
      this.activeList[level] = active
      this.activeList = [...this.activeList]
      this.active = active
      console.log(this.activeList)
      // 不存在子项则为末端叶子节点
      if (!this.active.children) return
      this.activeLevel = level + 1

    }
  }
}
</script>
<template>
  <form-select-wrap ref="wrap" :title="title"
                    :multiple="true"
                    :showPicker="showPicker"
                    @cancel="() => this.$emit('cancel')"
                    @confirm="onConfirm">
    <template v-if="loading || empty" #content>
      <empty-loading :loading="loading" :empty="empty"/>
    </template>
    <template v-else #content>
      <div id="form-select-wrap-cascader">
        <div id="content-opt" class="root-container">
          <span v-for="(item,index) in activeList" :key='index' class="opt" :class="{ active: active===item }"
                @click="optClick(item,index)">
            {{ item.reason }}
          </span>
        </div>
        <div class="bg-list"></div>
        <cell-list :list="list" :level="activeLevel" :cellActive="cellActive" @change="onCellChange">

        </cell-list>
      </div>
    </template>
  </form-select-wrap>
</template>
<style lang="less">
#form-select-wrap-cascader {
  #content-opt {
    padding-top: 10px;

    > .opt {
      display: inline-block;
      background-color: #F5F5F5;
      padding: 3px 10px 4px;
      margin-right: 10px;
      margin-bottom: 10px;

      &.active {
        background-color: @yellow;
        color: @black;
      }
    }
  }

  .bg-list {
    background: #F5F5F5;
    height: 1.5rem;
  }
}
</style>