/*
 * @Author: 张超越 
 * @Date: 2023-08-01 14:07:52 
 * @Last Modified by: 张超越
 * @Last Modified time: 2023-08-23 18:03:16
 */
<template>
  <div :class="['CommonDatePicker', isPC ? 'with-pc' : '']">
    <!-- 日历 -->
    <van-calendar
      ref="datePicker"
      :title="getTitle"
      :default-date="defaultDates"
      :poppable="false"
      :show-confirm="false"
      :first-day-of-week="1"
      :style="{ height: 'calc(100vh - 240px)', borderRadius: '8px' }"
      :formatter="formatter"
      :row-height="getRowHeight"
      :min-date="getMinDate"
      :max-date="getMaxDate"
      :show-title="showTitle"
      :allow-same-day="allowSameDay"
      closeable
      :type="calendarType"
      color="#3476FE"
      @confirm="handleDateConfirm"
    >
      <template #title>
        <div class="calendar-title-box">
          <div v-if="getTips" class="left">
            <div class="name">{{ getTips.left.name }}</div>
            <div class="text">{{ getTips.left.text }}</div>
          </div>
          <div class="center">{{ getTitle }}</div>
          <div v-if="getTips" class="right">
            <div class="name">{{ getTips.right.name }}</div>
            <div class="text">{{ getTips.right.text }}</div>
          </div>
        </div>
      </template>
    </van-calendar>

    <!-- 时间 -->
    <div class="dates">
      <div v-if="dateType === 'datetimerange'" class="pickers">
        <div class="date">
          <div class="title">{{ $t('开始时间') }}</div>
          <van-datetime-picker v-model="times.start" :filter="filterTime" :title="$t('开始时间')" :visible-item-count="3" type="time" :show-toolbar="false" />
        </div>
        <div class="date">
          <div class="title">{{ $t('结束时间') }}</div>
          <van-datetime-picker v-model="times.end" :filter="filterTime" :title="$t('结束时间')" :visible-item-count="3" type="time" :show-toolbar="false" />
        </div>
      </div>
      <div class="btns">
        <div class="btn" round type="info" @click="handleOK">{{ getOKButtonName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// Tools
import moment from 'moment'
moment.locale('zh-cn')

import { isArray } from 'lodash'

export default {
  name: 'CommonDatePicker',
  components: {},
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean,
      default: false
    },
    defaultDateTimes: {
      type: Array,
      default: () => []
    },
    isPC: {
      type: Boolean,
      default: false
    },
    allowSameDay: {
      type: Boolean,
      default: true
    },
    dateType: {
      type: String,
      default: () => 'datetimerange'
    },
    calendarType: {
      type: String,
      default: () => 'range'
    },
    minDate:{
      type: String,
      default: ''
    },
    maxDate:{
      type: String,
      default: ''
    },
    filterTime: {
      type: Function,
      default(type, options){ return options }
    },
    showNav: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      times: {
        start: '00:00:00',
        end: '23:59:59'
      },
      defaultDates: null,
      dates: []
    }
  },
  computed: {
    getDaysNumber() {
      // 计算起止时间天数
      if (!this.dates || !this.dates.length) return 0
      const [startDate, endDate] = this.dates
      const start = moment(startDate)
      const end = moment(endDate)
      return end.diff(start, 'days') + 1
    },
    getTitle() {
      return this.getDaysNumber ? `共${this.getDaysNumber}天` : '时间选择'
    },
    getTips() {
      // 计算起止时间提示
      if (!this.dates || !this.dates.length) return null

      const [startDate, endDate] = this.dates
      const start = moment(startDate)
      const end = moment(endDate)

      // dates 
      const { start: startTime, end: endTime } = this.times

      // text 周一 14:00
      const left = {
        name: start.format('MM月DD日'),
        text: start.format('ddd') + ' ' + startTime
      }
      const right = {
        name: end.format('MM月DD日'),
        text: end.format('ddd') + ' ' + endTime
      }
      return { left, right }
    },
    getRowHeight() {
      return this.isPC ? 50 : 64
    },
    getMinDate() {
      console.log('子组件getMinDate:',this.minDate,)
      if (!this.minDate) return moment(new Date()).toDate() // 默认三年前
      return moment(this.minDate).toDate()
      
    },
    getMaxDate() {
      console.log('子组件getMaxDate:',this.maxDate)
      // 默认三年后
      if (!this.maxDate) return moment().subtract(-3, 'years').toDate()
      return moment(this.maxDate).toDate()
    },
    getOKButtonName() {
      return this.dateType === 'datetimerange' ? this.$t('确定时间') : this.$t('确定日期')
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.scrollToDate()
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    init() {
      // 初始化日期时间
      if (!this.defaultDateTimes || !isArray(this.defaultDateTimes) || !this.defaultDateTimes.length) {
        return
      }
      const [startDate, endDate] = this.defaultDateTimes
      this.defaultDates = [moment(startDate).toDate(), moment(endDate).toDate()]
      this.dates = [moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')]
    },
    scrollToDate() {
      this.$refs['datePicker'].scrollToDate(moment().toDate())
    },
    formatter(day) {
      const isToday = moment().isSame(moment(day.date), 'day')
      if (isToday) {
        day.topInfo = this.$t('今日')
        day.className = 'today'
      }
      return day
    },
    handleDateConfirm(data) {
      if (this.calendarType == 'range') {
        const [startDate, endDate] = data || []
        this.dates = [moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')]
      } else {
        this.dates = [moment(data).format('YYYY-MM-DD')]
      }
    },
    handleOK() {
      // 判断是否选择起止日期
      if (!this.dates) {
        this.$toast(this.$t('请选择日期'))
        return
      }
      const { start: startTime, end: endTime } = this.times
      if (this.calendarType === 'range') {
        if (!this.dates.length) {
          this.$toast(this.$t('请选择起止时间'))
          return
        }
        const [ startDate, endDate ] = this.dates
        const dateTimes = [moment(`${startDate} ${startTime}`).format('YYYY-MM-DD HH:mm:ss'), moment(`${endDate} ${endTime}`).format('YYYY-MM-DD HH:mm:ss')]
        this.$emit('confirm', { dateTimes })
      } else {
        const [ date ] = this.dates
        if (!date) {
          this.$toast(this.$t('请选择日期'))
          return
        }
        const dateTimes = [moment(`${date} ${startTime}`).format('YYYY-MM-DD HH:mm:ss'), moment(`${date} ${endTime}`).format('YYYY-MM-DD HH:mm:ss')]
        this.$emit('confirm', { dateTimes })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.CommonDatePicker {
  width: 100%;
  height: 100vh;
  background-color: #F2F2F2;
  padding: 16px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  &.with-pc {
    width: 100%;
    height: 500px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0;
    box-shadow: 0px 0px 5px #EEE;
    .dates {
      position: absolute;
    }
  }

  /deep/ .van-calendar__header {
    box-shadow: none !important;
  }

  /deep/ .today {
    // color: @yellow-text !important;
    border-radius: 6px;
    background: #E5E5E5;
    &.van-calendar__day--start {
      color: #FFF !important;
    }
  }

  /deep/ .van-calendar__bottom-info {
    color: #FFF !important;
    font-size: 10px !important;
  }

  .calendar-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: 56px;
    line-height: initial;

    .left, .right {
      padding: 16px;
      .text {
        color: #7D7D7D;
        font-size: 14px;
      }

      .name {
        color: #323232;
        font-size: 16px;
      }
    }

    .center {
      font-size: 14px;
      color: #323232;
      flex: 1;
    }
  }

  .dates {
    @height: 240px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    // height: @height;
    max-height: @height;
    box-shadow: 0px -4px 8px #0000000d;
    background-color: #fff;
    .pickers {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    .btns {
      height: 60px;
      text-align: center;
      box-sizing: border-box;
      padding: 6px 18px;
      .btn {
        width: 100%;
        border-radius: 35px;
        background: @yellow;
        color: @black;
        height: 42px;
        line-height: 42px;
      }
    }

    .date {
      flex: 1 !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      /deep/ .van-picker__columns {
        height: calc(@height - 44px - 60px) !important;
      }

      /deep/ .van-datetime-picker {
        width: 50%;
      }
    }

    .title {
      height: 44px;
      line-height: 44px;
      text-align: center;
      color: #323232;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
</style>
