<template>
  <van-popup v-model="visible" position="bottom" :style="{ width: '100%' }">
    <van-datetime-picker
      v-model="date"
      :type="type"
      :min-date="minDate"
      :max-date="maxDate"
      :filter="filter"
      @confirm="onConfirm"
      @cancel="onCancel"
    />
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      type: 'date',
      visible: false,
      date: new Date(),
      minDate: undefined,
      maxDate: undefined,
      filter: undefined
    }
  },
  methods: {
    init({ date, minDate, maxDate, filter }) {
      this.minDate = minDate
      this.maxDate = maxDate
      this.filter = filter
      this.date = date
      this.$nextTick(() => {
        this.visible = true
      })
    },
    onConfirm(value) {
      this.resolve && this.resolve(value)
      this.visible = false
    },
    onCancel() {
      this.reject && this.reject()
      this.visible = false
    }
  }
}
</script>

<style scoped>

</style>
